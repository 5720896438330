import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Tag } from '../interface/tag';
import { DataService } from './data.service';

interface Message {
  _id: string;
  messageStatus: number;
  title: string;
  description?: string;
  image?: string;
  link?: string;
  type: number;
  segment: string;
  createdAt: Date;
}
@Injectable({
    providedIn: 'root'
  })
  export class T5MessagesService {
   private _isFromMatrix = true;

    BASE_URL = `${environment.mkgoURL}/api/v1/t5-messages`;

    constructor(
      private dataService: DataService,
      private http: HttpClient
    ) { }
    // messageStatus 1 = não lida, 2 = lida

    async getAll(): Promise<Message[]> {
      const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
      const resp = await firstValueFrom(
        this.http.get<{t5message: Message[]}>(`${this.BASE_URL}?messageStatus=1`, header)
      );
      return resp.t5message;
    }

    async markAsRead(messageId: string): Promise<any> {
      const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
      return firstValueFrom(
        this.http.put(`${this.BASE_URL}/${messageId}`, { messageStatus: 2 }, header)
      );
    }
  }
