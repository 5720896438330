import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface T5MessageData {
  _id: string;
  title: string;
  description?: string;
  link?: string;
  image?: string;
  createdAt: Date;
  type: number;
  segment: string;
}

@Component({
  selector: 'app-t5-msg-dialog',
  templateUrl: './t5-msg-dialog.component.html',
  styleUrls: ['./t5-msg-dialog.component.scss']
})
export class T5MsgDialogComponent {
  isFullImageVisible = false;

  constructor(
    public dialogRef: MatDialogRef<T5MsgDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: T5MessageData
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  showFullImage(): void {
    this.isFullImageVisible = true;
    document.body.style.overflow = 'hidden';
  }

  hideFullImage(): void {
    this.isFullImageVisible = false;
    document.body.style.overflow = '';
  }
}
