import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { PreEditorComponent } from "../component/dialog/pre-editor/pre-editor.component";
import { LayoutService } from "../service/layout.service";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    URL_TO_INTERCEPT = environment.migrate.URL;

    constructor(
        private _layout: LayoutService,
        private _dialog: MatDialog,
        private _activatedRoute: ActivatedRoute
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.url.endsWith("graphql")) {
            // The graphql requests can't have line breaks(\n) into queries
            if (request.body?.query) {
                const cleanQuery = (request.body.query as string).replace(/\s+/g, ' ').trim();
                const cloned = request.clone({
                    body: { ...request.body, query: cleanQuery },
                });

                return next.handle(cloned);
            }
        }

        const strToIntercept = this._activatedRoute.snapshot.queryParamMap.get('intercept');
        const methodToIntercept = (this._activatedRoute.snapshot.queryParamMap.get('method') || "").toUpperCase();

        if (strToIntercept && request.url.includes(strToIntercept) && methodToIntercept === request.method) {
            this._layout.loader = false;
            return this._dialog
                .open(PreEditorComponent, {
                    data: request,
                    minWidth: '80vw',
                    width: "100",
                    height: "100",
                })
                .afterClosed()
                .pipe(
                    switchMap((editedBody: any) => {
                        const editedReq = request.clone({
                            body: editedBody,
                        });

                        return next.handle(editedReq);
                    })
                );
        } else {
            return next.handle(request);
        }
    }
}
