import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { UserService } from './service/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { API_ERRORS } from './shared/lists/errors';
import { DataService } from './service/data.service';
import { CompanyService } from './service/company.service';
import { LayoutService } from './service/layout.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MkgUser } from './interface/user';
import { SnackbarService } from './service/snackbar.service';
import { AccessProfileService } from './component/page/company/access-profile/services/access-profile.service';
import { of } from 'rxjs';
import { SessionOSservice } from './service/session/session-os.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  constructor(
    public layout: LayoutService,
    private companyService: CompanyService,
    private userService: UserService,
    private dataService: DataService,
    private router: Router,
    private auth: AngularFireAuth,
    private activatedRoute: ActivatedRoute,
    private _snackbar: SnackbarService,
    private _accessProfileService: AccessProfileService,
    private _sessionOSService: SessionOSservice
  ) { }

  ngOnInit() {

    this.auth.authState.subscribe((firebaseUser) => {
      if (firebaseUser) {
        this.userService.getCurrent().subscribe({
          next: user => this.dataService.user$.next(new MkgUser(user)),
          error: async (error) => {
            if (!(error instanceof HttpErrorResponse)) {
              throw error
            }
            const code: number = error.error?.error;
            if (code == API_ERRORS.userNotFound || code == API_ERRORS.invalidPermissions) { // inexistent

              this.layout.loader = false;
              if (!firebaseUser.emailVerified) {
                await firebaseUser.sendEmailVerification();
                this._snackbar.info('FIREBASE.VERIFICATION_SENT');
              } else if (code == API_ERRORS.invalidPermissions) {
                this._snackbar.info('FIREBASE.NOT_VERIFIED');
              }
              const userInfo = await this.userService.updateCurrent({
                name: firebaseUser.displayName,
                phone1: '-',
                email: firebaseUser.email
              });

              this.userService.getCurrent().subscribe(user => {

                this.dataService.user$.next(new MkgUser(user))
              });
            }

            switch (error.status) {
              case 0: // API offline
                this._snackbar.error('LOGIN.conection_error')
                break;
              case 401: // Unauthorized (Token expirated)
                await firebaseUser.getIdTokenResult();
                // await this.auth.currentUser.getIdTokenResult()
                // await this.userService.getCurrent();
                break;
              default:
                break;
            }

          }
        })
      } else {
        this.dataService.company$.next(null);
        this.dataService.user$.next(null);
        this.router.navigate(['sign-in']);
      }
    });


    // take companyId on navigator url and retrieve it
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        // get params of nested modules
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap(route => route.params),
      map(params => ({ companyId: params['company'], url: this.router.url })),
    ).subscribe(({ companyId, url }) => {
      const isEditingCompany = url.includes("/edit/company/");
      this.layout.addLoaderTrigger("AppComponent.ngOnInit().router.events.subscribe()");
      if (companyId && !isEditingCompany) {
        if (this.dataService.company?.id !== companyId) {
          this.companyService.get(companyId, true)
            .then(company => {
              this.dataService.company$.next(company);
              if (company) {
                // company changed, get the userAccessProfile into that
                this.getUserProfile();
              }
            })
            .catch(error => {
              console.error(`Empresa de id ${companyId} não foi encontrada`, error);
              if (error instanceof HttpErrorResponse) {
                if (error.error.error === API_ERRORS.notFound) {
                  this.router.navigate(['/company'])
                }
              }
            }).finally(() => {
              this.layout.removeLoaderTrigger("AppComponent.ngOnInit().router.events.subscribe()")
            });
        } else {
          this.layout.removeLoaderTrigger("AppComponent.ngOnInit().router.events.subscribe()")
        }
      } else {
        this.dataService.company$.next(null)
        this.layout.removeLoaderTrigger("AppComponent.ngOnInit().router.events.subscribe()");
      }

      // avoid to show the add FAB button from one page to other page
      this.layout.add = null;
    })

  }


  getUserProfile() {
    if (this.dataService.user && this.dataService.company) {
      if (this.dataService.user.profileId) {
        this._accessProfileService.get(this.dataService.user.profileId)
          .pipe(catchError((err) => {
            console.error(`Perfil de acesso com ID "${this.dataService.user.profileId}" não encontrado`)
            return of(undefined);
          }))
          .subscribe(profile => {
            this.dataService.accessProfile$.next(profile);
          })
      } else {
        this.dataService.accessProfile$.next(null);
      }
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  saveHashBeforeReload(_event?: any) {
    this._sessionOSService.saveHashBeforeReload();
  }

}

