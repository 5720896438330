
<div class="mkg-frontpanel">
  <h1>
    {{ (companyData ? 'ACTION.COMPANY.UPDATE' : 'ACTION.COMPANY.ADD') | translate }}
  </h1>
  <div class="main-container">
    <form [formGroup]="form"
        autocomplete="off">
      <div class="form-content">
  
        <!-- Social name -->
        <mat-form-field class="col-12 col-md-6 col-lg-4">
          <mat-label>{{ 'PROPERTY.COMPANY.SOCIAL_NAME' | translate }}</mat-label>
          <input matInput
                required
                autocomplete="off"
                maxlength="60"
                formControlName="socialName" />
          <mat-error *ngIf="form.get('socialName').errors">
            {{ form.get('socialName').errors | error | translate : form.get('socialName').errors }}
          </mat-error>
        </mat-form-field>
    
        <!-- Fancy name -->
        <mat-form-field class="col-12 col-md-6 col-lg-4">
          <mat-label>{{ 'PROPERTY.COMPANY.FANCY_NAME' | translate }}</mat-label>
          <input matInput
                required
                autocomplete="off"
                maxlength="60"
                formControlName="fancyName" />
          <mat-error *ngIf="form.get('fancyName').errors">
            {{ form.get('fancyName').errors | error | translate : form.get('fancyName').errors }}
          </mat-error>
        </mat-form-field>
    
        <!-- CNPJ -->
        <mat-form-field class="col-12 col-md-6 col-lg-4">
          <mat-label>{{ 'PROPERTY.COMPANY.CNPJ' | translate }}</mat-label>
          <input matInput
                required
                autocomplete="off"
                cnpj
                maxlength="18"
                formControlName="cnpj" />
          <mat-error *ngIf="form.get('cnpj').errors">
            {{ form.get('cnpj').errors | error | translate: form.get('cnpj').errors }}
          </mat-error>
          <mat-hint *ngIf="form.hasError('controlsEquals')">
            CNPJ e CNPJ-Matriz precisam ser diferentes
          </mat-hint>
        </mat-form-field>
    
        <!-- CNPJ matrix -->
        <mat-form-field class="col-12 col-md-6 col-lg-4">
          <mat-label>CNPJ Matriz</mat-label>
          <input matInput
                autocomplete="off"
                cnpj
                maxlength="18"
                formControlName="cnpjMatrix" />
          <mat-error *ngIf="form.get('cnpjMatrix').errors">
            {{ form.get('cnpjMatrix').errors | error | translate : form.get('cnpjMatrix').errors }}
          </mat-error>
          <mat-hint *ngIf="form.hasError('controlsEquals')">
            CNPJ e CNPJ-Matriz precisam ser diferentes
          </mat-hint>
        </mat-form-field>
    
        <ng-container *ngIf="!form.value.makena">
    
          <!-- State registration -->
          <mat-form-field class="col-12 col-md-6 col-lg-4">
            <mat-label>{{ 'PROPERTY.COMPANY.STATE_REGISTRATION' | translate }}</mat-label>
            <input matInput
                  [required]="!form.value.makena"
                  autocomplete="off"
                  maxlength="18"
                  formControlName="stateRegistration" />
            <mat-error *ngIf="form.get('stateRegistration').errors">
              {{ form.get('stateRegistration').errors | error | translate : form.get('stateRegistration').errors }}
            </mat-error>
          </mat-form-field>
    
          <!-- City registration -->
          <mat-form-field class="col-12 col-md-6 col-lg-4">
            <mat-label>Inscrição municipal</mat-label>
            <input matInput
                  required
                  maxlength="15"
                  formControlName="IM"
                  autocomplete="off">
            <mat-error *ngIf="form.get('IM').errors">
              {{ form.get('IM').errors | error | translate : form.get('IM').errors }}
            </mat-error>
          </mat-form-field>
    
          <!-- tributMunicipio -->
          <mat-form-field class="col-12 col-md-6 col-lg-4">
            <mat-label>Cód. trib. do município</mat-label>
            <input matInput
                  autocomplete="off"
                  maxlength="20"
                  title="Código de tributação do município"
                  placeholder="EX: 1401"
                  formControlName="tributMunicipio">
            <mat-error *ngIf="form.get('tributMunicipio').errors">
              {{ form.get('tributMunicipio').errors | error | translate : form.get('tributMunicipio').errors }}
            </mat-error>
          </mat-form-field>
    
          <!-- CRT -->
          <mat-form-field class="col-12 col-md-6 col-lg-4">
            <mat-label>{{ 'PROPERTY.COMPANY.CRT' | translate }}</mat-label>
            <mat-select formControlName="crt"
                        required>
              <mat-option [value]="'1'">Simples Nacional </mat-option>
              <mat-option [value]="'2'"
                          title="Simples Nacional – excesso de sublimite de receita bruta">Simples Nacional – excesso de
                sublimite de receita bruta </mat-option>
              <mat-option [value]="'3'">Regime Normal </mat-option>
              <mat-option [value]="'4'">Simples Nacional – Microempreendedor Individual – MEI </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('crt').errors">
              {{ form.get('crt').errors | error | translate : form.get('crt').errors }}
            </mat-error>
          </mat-form-field>
    
          <!-- regEspTrib -->
          <mat-form-field class="col-12 col-md-6 col-lg-4">
            <mat-label>
              Reg. Esp. de Trib. do Prestador
            </mat-label>
            <mat-select formControlName="regEspTrib"
                        title="Regime Especial de Tributação do Prestador">
              <mat-option *ngFor="let trib of regEspTribList"
                          [title]="trib.description"
                          [value]="trib.code">
                {{ trib.code }} - {{ trib.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
    
          <!-- cVerificaRPS -->
          <mat-form-field class="col-12 col-md-6 col-lg-2">
            <mat-label>Cod. Verif. RPS</mat-label>
            <input matInput
                  title="Código de Verificação de RPS"
                  formControlName="verificaRPS">
          </mat-form-field>
    
          <mat-form-field class="col-12 col-md-6 col-lg-2">
            <mat-label>Natureza/Exigibilidade</mat-label>
            <mat-select formControlName="exigibilidade"
                        [compareWith]="SelectUtilities.compareByCode"
                        title="Natureza da operação/Exigibilidade de ISS">
              <mat-option *ngFor="let natureza of NATUREZAS"
                          [title]="natureza.description"
                          [value]="natureza.code">
                {{ natureza.code }} - {{ natureza.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
    
          <!-- munIncidISS -->
          <mat-form-field class="col-12 col-md-6 col-lg-2">
            <mat-label>Município de incidência de ISS</mat-label>
            <mat-select formControlName="munIncidISS"
                        title="Município de incidência de ISS">
              <mat-option *ngFor="let option of ['Não informa',  'Cliente','Prestador']"
                          [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
    
          <mat-form-field class="col-12 col-md-6 col-lg-2">
            <mat-label>Alíquota ISS</mat-label>
            <input matInput
                  money
                  class="txt-end"
                  [limit]="100"
                  [decimalPoints]="4"
                  formControlName="ISS">
            <span matTextSuffix>%</span>
          </mat-form-field>
    
          <!-- cnaeCode -->
          <mat-form-field class="col-12 col-md-6 col-lg-2">
            <mat-label>Código CNAE</mat-label>
            <input matInput
                  formControlName="cnaeCode"
                  title="Código CNAE - Classificação Nacional de Atividades Econômicas"
                  maxlength="10"
                  required
                  integer>
            <mat-error *ngIf="form.get('cnaeCode').errors">
              {{ form.get('cnaeCode').errors | error | translate : form.get('cnaeCode').errors }}
            </mat-error>
          </mat-form-field>
    
          <!-- last NSU -->
          <mat-form-field class="col-12 col-md-6 col-lg-2">
            <mat-label>Último NSU</mat-label>
            <input matInput
                  [required]="form.value.migrateIntegrated"
                  formControlName="lastNSU"
                  maxlength="15">
            <mat-error *ngIf="form.get('lastNSU').errors">
              {{ form.get('lastNSU').errors | error | translate : form.get('lastNSU').errors }}
            </mat-error>
          </mat-form-field>
    
          <!-- access key -->
          <mat-form-field class="col-12 col-md-6 col-lg-4">
            <mat-label>Chave de acesso</mat-label>
            <input matInput
                  [required]="form.value.migrateIntegrated"
                  formControlName="accessKeyInvoice">
            <mat-error *ngIf="form.get('accessKeyInvoice').errors">
              {{ form.get('accessKeyInvoice').errors | error | translate : form.get('accessKeyInvoice').errors }}
            </mat-error>
          </mat-form-field>
    
          <!-- NFe number -->
          <mat-form-field class="col-12 col-md-6 col-lg-2">
            <mat-label>Nº NFe</mat-label>
            <input matInput
                  required
                  integer
                  maxlength="9"
                  formControlName="numberInvoice">
            <mat-error *ngIf="form.get('numberInvoice').errors">
              {{ form.get('numberInvoice').errors | error | translate : form.get('numberInvoice').errors }}
            </mat-error>
          </mat-form-field>
    
          <!-- NFe serial -->
          <mat-form-field class="col-12 col-md-6 col-lg-2">
            <mat-label>Série NFe</mat-label>
            <input matInput
                  required
                  integer
                  maxlength="3"
                  placeholder="Ex: 001"
                  formControlName="serieInvoice">
            <mat-error *ngIf="form.get('serieInvoice').errors">
              {{ form.get('serieInvoice').errors | error | translate : form.get('serieInvoice').errors }}
            </mat-error>
          </mat-form-field>
    
          <!-- NFCe number -->
          <mat-form-field class="col-12 col-md-6 col-lg-2">
            <mat-label>Nº NFCe</mat-label>
            <input matInput
                  required
                  integer
                  maxlength="9"
                  formControlName="numberInvoiceNFC">
            <mat-error *ngIf="form.get('numberInvoiceNFC').errors">
              {{ form.get('numberInvoiceNFC').errors | error | translate : form.get('numberInvoiceNFC').errors }}
            </mat-error>
          </mat-form-field>
    
          <!-- NFCe serial -->
          <mat-form-field class="col-12 col-md-6 col-lg-2">
            <mat-label>Série NFCe</mat-label>
            <input matInput
                  required
                  integer
                  maxlength="3"
                  placeholder="Ex: 001"
                  formControlName="serieInvoiceNFC">
            <mat-error *ngIf="form.get('serieInvoiceNFC').errors">
              {{ form.get('serieInvoiceNFC').errors | error | translate : form.get('serieInvoiceNFC').errors }}
            </mat-error>
          </mat-form-field>
    
          <!-- NFSe number (RPS) -->
          <mat-form-field class="col-12 col-md-6 col-lg-2">
            <mat-label>Nº da NFSe</mat-label>
            <input matInput
                  required
                  integer
                  maxlength="15"
                  formControlName="numberInvoiceNFS">
            <mat-error *ngIf="form.get('numberInvoiceNFS').errors">
              {{ form.get('numberInvoiceNFS').errors | error | translate : form.get('numberInvoiceNFS').errors }}
            </mat-error>
          </mat-form-field>
    
          <!-- NFSe serial -->
          <mat-form-field class="col-12 col-md-6 col-lg-2">
            <mat-label>Série NFSe</mat-label>
            <input matInput
                  required
                  maxlength="5"
                  uppercase
                  placeholder="Ex: NFSE1"
                  formControlName="serieInvoiceNFS">
            <mat-error *ngIf="form.get('serieInvoiceNFS').errors">
              {{ form.get('serieInvoiceNFS').errors | error | translate : form.get('serieInvoiceNFS').errors }}
            </mat-error>
          </mat-form-field>
    
        </ng-container>
    
        <!-- gerencial number -->
        <mat-form-field class="col-12 col-md-6 col-lg-2"
                        *ngIf="!form.value.makena">
          <mat-label>Nº gerencial</mat-label>
          <input matInput
                required
                integer
                maxlength="9"
                formControlName="numberGerencial">
          <mat-error *ngIf="form.get('numberGerencial').errors">
            {{ form.get('numberGerencial').errors | error | translate : form.get('numberGerencial').errors }}
          </mat-error>
        </mat-form-field>
    
        <!-- gerencial serial -->
        <mat-form-field class="col-12 col-md-6 col-lg-2"
                        *ngIf="!form.value.makena">
          <mat-label>Série gerencial</mat-label>
          <input matInput
                required
                integer
                maxlength="3"
                uppercase
                placeholder="Ex: 001"
                formControlName="serieGerencial">
          <mat-error *ngIf="form.get('serieGerencial').errors">
            {{ form.get('serieGerencial').errors | error | translate : form.get('serieGerencial').errors }}
          </mat-error>
        </mat-form-field>
    
        <!-- Tab -->
        <mat-form-field class="col-12 col-md-6 col-lg-2"
                        *ngIf="form.value.makena">
          <mat-label>Módulos</mat-label>
          <mat-select formControlName="tab"
                      multiple>
    
            <mat-option *ngFor="let ta of companyTabs"
                        [value]="COMPANY_TAB[ta]">{{ ta }}</mat-option>
          </mat-select>
        </mat-form-field>
    
        <!-- Brand -->
        <mat-form-field class="col-12 col-md-6 col-lg-2"
                        *ngIf="form.value.makena">
          <mat-label>Marca</mat-label>
          <mat-select formControlName="brand"
                      required>
    
            <mat-option *ngFor="let brd of companyBrands"
                        [value]="brd">{{brd}}</mat-option>
          </mat-select>
        </mat-form-field>
    
        <!-- Phone 1 -->
        <mat-form-field class="col-12 col-md-6 col-lg-2">
          <mat-label>{{ 'PROPERTY.PERSON.PHONE' | translate }}</mat-label>
          <input matInput
                [required]="isMakena"
                autocomplete="off"
                formControlName="phone"
                minlength="10"
                maxlength="17"
                phone />
          <mat-hint *ngIf="isMakena">Para pesquisa de satisfção</mat-hint>
          <mat-error *ngIf="form.get('phone').errors">
            {{ form.get('phone').errors | error | translate : form.get('phone').errors }}
          </mat-error>
        </mat-form-field>
    
        <!-- Phone 2 -->
        <mat-form-field class="col-12 col-md-6 col-lg-2">
          <mat-label>{{ 'PROPERTY.PERSON.PHONE_2' | translate }}</mat-label>
          <input matInput
                autocomplete="off"
                formControlName="phone2"
                maxlength="17"
                phone />
          <mat-error *ngIf="form.get('phone2').errors">
            {{ form.get('phone2').errors | error | translate : form.get('phone2').errors }}
          </mat-error>
        </mat-form-field>
    
        <!-- Email -->
        <mat-form-field class="col-12 col-md-6 col-lg-4">
          <mat-label>{{ 'PROPERTY.PERSON.EMAIL' | translate }}</mat-label>
          <input matInput
                type="email"
                autocomplete="off"
                maxlength="254"
                formControlName="email" />
          <mat-error *ngIf="form.get('email').errors">
            {{ form.get('email').errors | error | translate : form.get('email').errors }}
          </mat-error>
        </mat-form-field>
    
        <!-- Site -->
        <mat-form-field class="col-12 col-md-6 col-lg-4">
          <mat-label>{{ 'PROPERTY.COMPANY.SITE' | translate }}</mat-label>
          <input matInput
                type="text"
                autocomplete="off"
                maxlength="200"
                formControlName="site" />
          <mat-error *ngIf="form.get('site').errors">
            {{ form.get('site').errors | error | translate : form.get('site').errors }}
          </mat-error>
        </mat-form-field>
    
        <ng-container formGroupName="address">
          <!-- CEP -->
          <mat-form-field class="col-12 col-md-6 col-lg-2">
            <mat-label>{{ 'PROPERTY.ADDRESS.CEP' | translate }}</mat-label>
            <input matInput
                  [required]="!isMakena"
                  autocomplete="off"
                  formControlName="cep"
                  cep />
            <mat-error *ngIf="form.get('address.cep').errors">
              {{ form.get('address.cep').errors | error | translate : form.get('address.cep').errors }}
            </mat-error>
          </mat-form-field>
    
          <!-- Type -->
          <mat-form-field class="col-12 col-md-6 col-lg-2">
            <mat-label>{{ 'PROPERTY.ADDRESS.TYPE' | translate }}</mat-label>
            <input matInput
                  [required]="!isMakena"
                  autocomplete="off"
                  formControlName="type"
                  maxlength="30"
                  placeholder="Ex: Rua, Avenida">
            <mat-error *ngIf="form.get('address.type').errors">
              {{ form.get('address.type').errors | error | translate : form.get('address.type').errors }}
            </mat-error>
          </mat-form-field>
    
          <!-- Street -->
          <mat-form-field class="col-12 col-md-6 col-lg-4">
            <mat-label>{{ 'PROPERTY.ADDRESS.STREET' | translate }}</mat-label>
            <input matInput
                  [required]="!isMakena"
                  autocomplete="off"
                  minlength="2"
                  maxlength="60"
                  formControlName="street" />
            <mat-error *ngIf="form.get('address.street').errors">
              {{ form.get('address.street').errors | error | translate : form.get('address.street').errors }}
            </mat-error>
          </mat-form-field>
    
          <!-- Number -->
          <mat-form-field class="col-12 col-md-6 col-lg-2">
            <mat-label>{{ 'PROPERTY.ADDRESS.NUMBER' | translate }}</mat-label>
            <input matInput
                  [required]="!isMakena"
                  autocomplete="off"
                  maxlength="60"
                  formControlName="number" />
            <mat-error *ngIf="form.get('address.number').errors">
              {{ form.get('address.number').errors | error | translate : form.get('address.number').errors }}
            </mat-error>
          </mat-form-field>
    
          <!-- Complement -->
          <mat-form-field class="col-12 col-md-6 col-lg-2">
            <mat-label>{{ 'PROPERTY.ADDRESS.COMPLEMENT' | translate }}</mat-label>
            <input matInput
                  autocomplete="off"
                  maxlength="60"
                  formControlName="complement" />
          </mat-form-field>
    
          <!-- neighborhood -->
          <mat-form-field class="col-12 col-lg-4">
            <mat-label>{{ 'PROPERTY.ADDRESS.NEIGHBORHOOD' | translate }}</mat-label>
            <input matInput
                  [required]="!isMakena"
                  autocomplete="off"
                  maxlength="60"
                  formControlName="neighborhood" />
            <mat-error *ngIf="form.get('address.neighborhood').errors">
              {{ form.get('address.neighborhood').errors | error | translate : form.get('address.neighborhood').errors }}
            </mat-error>
          </mat-form-field>
    
          <!-- state -->
          <mat-form-field class="col-12 col-md-6 col-lg-3">
            <mat-label>{{ 'OBJECT.STATE' | translate }}</mat-label>
            <mat-select formControlName="state"
                        required>
              <mat-option *ngFor="let state of states"
                          [value]="state.id">
                {{ state.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('address.state').errors">
              {{ form.get('address.state').errors | error | translate : form.get('address.state').errors }}
            </mat-error>
          </mat-form-field>
    
          <!-- city -->
          <mat-form-field class="col-12 col-md-6 col-lg-3">
            <mat-label>{{ 'OBJECT.CITY' | translate }}</mat-label>
            <mat-select formControlName="city"
                        required>
              <mat-option *ngFor="let city of form.value.address.state | address: states"
                          [value]="city.id">
                {{ city.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('address.city').errors">
              {{ form.get('address.city').errors | error | translate : form.get('address.city').errors }}
            </mat-error>
          </mat-form-field>
        </ng-container>
        <mat-form-field class="col-12 col-lg-4">
          <mat-label>TokenWhats</mat-label>
          <input matInput
                autocomplete="off"
                maxlength="60"
                formControlName="tokenWhats" />
        </mat-form-field>
    
        <ng-container *ngIf="!form.value.makena">
    
          <mat-checkbox color="primary"
                        class="col-6 col-md-3"
                        [checked]="form.value.controlStock"
                        formControlName="controlStock">Controla estoque</mat-checkbox>
          <mat-checkbox color="primary"
                        class="col-6 col-md-3"
                        [checked]="form.value.discountControl"
                        formControlName="discountControl">Controla Desconto</mat-checkbox>
          <mat-checkbox color="primary"
                        class="col-6 col-md-3"
                        [checked]="form.value.complementaryOS"
                        formControlName="complementaryOS">Adicionar Orçamento Complementar</mat-checkbox>
          <mat-checkbox color="primary"
                        class="col-6 col-md-3"
                        [checked]="form.value.osCount"
                        formControlName="osCount">Número de OS/Orçamento separados</mat-checkbox>
          <mat-checkbox color="primary"
                        class="col-6 col-md-3"
                        [checked]="form.value.migrateIntegrated"
                        formControlName="migrateIntegrated">Emite documento fiscal</mat-checkbox>
          <mat-checkbox color="primary"
                        class="col-6 col-md-3"
                        [checked]="form.value.openOS"
                        formControlName="openOS">Permitir abrir OS diretamente</mat-checkbox>
          <mat-checkbox color="primary"
                        class="col-6 col-md-3"
                        [checked]="form.value.sendCode"
                        formControlName="sendCode">Não enviar código de peças no PDF</mat-checkbox>
    
          <ng-container *ngIf="form.value.migrateIntegrated; else elseTemplate">
            <mat-form-field class="col-12 col-md-3">
              <mat-label>Padrão NFSe</mat-label>
              <mat-select formControlName="patternNFSE">
                <mat-option *ngFor="let pattern of NFSE_PATTERNS"
                            [value]="pattern">
                  {{ pattern }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-template #elseTemplate>
            <div class="col-12"></div>
          </ng-template>
          
          <div class="row mx-2">
            <div class="col-12 col-lg-6">
              <mat-form-field class="add-cpf-cnpj">
                <mat-label>CPF/CNPJ</mat-label>
                <input matInput #cnpjCpfinput type="text" maxlength="18" minlength="11" [disabled]="form.get('authorized').value.length >= 10"> 
                <button matSuffix mat-icon-button (click)="[addNewCpfOrCnpj(cnpjCpfinput.value), cnpjCpfinput.value = '']">
                  <mat-icon>add</mat-icon>
                </button>
              </mat-form-field>
              <div class="authorized-cpf-and-cnpj-list">
                @for (item of form.get('authorized').value; track $index) {
                  <div class="d-flex flex-row gap-0 justify-content-start align-items-center flex-wrap w-auto">
                    <p class="m-0 auth-documents-items"> {{ item | document }}</p>
                    <div class="d-flex flex-row justify-content-start align-items-center mr-3 me-3">
                      <button mat-icon-button (click)="toggleEdit(cnpjCpfInputEditBlock)">
                        <mat-icon>
                          edit
                        </mat-icon>
                      </button>
                      <button mat-icon-button (click)="removeAuthorizedItem($index)">
                        <mat-icon>
                          delete
                        </mat-icon>
                      </button>
                    </div>
                    <div #cnpjCpfInputEditBlock class="d-none align-items-center flex-wrap w-auto">
                      <mat-form-field>
                        <mat-label>CPF/CNPJ</mat-label>
                        <input matInput #cnpjCpfInputEdit value="{{ item }}" type="text" maxlength="18" minlength="11"> 
                      </mat-form-field>
                      <button mat-icon-button class="mb-2" (click)="editCpfOrCnpj($index, { value: cnpjCpfInputEdit.value, htmlElement: cnpjCpfInputEditBlock })">
                        <mat-icon>check</mat-icon>
                      </button>
                      <button mat-icon-button class="mb-2" (click)="toggleEdit(cnpjCpfInputEditBlock, true)">
                        <mat-icon>close</mat-icon>
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="d-flex flex-column align-items-end gap-1">
                <div class="logo-container"
                    *ngIf="form.value.id">
                  <div class="logo-header">
                    <div class="logo-title">{{ 'PROPERTY.COMPANY.COMPANY_LOGO' | translate }}</div>
                    <button aria-label="delete"
                            *ngIf="companyLogoBlob || form.value.companyLogo"
                            class="delete-button"
                            mat-icon-button
                            type="button"
                            (click)="deleteCompanyLogo()">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                  <div (click)="companyLogoFile.click()">
                    <mat-icon *ngIf="!companyLogoBlob && !form.value.companyLogo"
                              class="add-logo"
                              [inline]="true"> add_photo_alternate </mat-icon>
                    <canvas id="company-logo-canvas"
                            class="photo"
                            hidden></canvas>
                    <img *ngIf="!companyLogoBlob && form.value.companyLogo"
                        class="photo"
                        [src]="form.value.companyLogo" />
                    <input #companyLogoFile
                          hidden
                          type="file"
                          accept="image/*"
                          formControlName="companyLogoInput"
                          (click)="form.get('companyLogoInput').setValue('')"
                          (change)="onChangeCompanyLogo($event)" />
                  </div>
                </div>
                <div class="logo-container"
                    *ngIf="form.value.id">
                  <div class="logo-header">
                    <div class="logo-title">{{ 'PROPERTY.COMPANY.ASSOCIATION_LOGO' | translate }}</div>
                    <button aria-label="delete"
                            *ngIf="associationLogoBlob || form.value.associateLogo"
                            class="delete-button"
                            mat-icon-button
                            type="button"
                            (click)="deleteAssociationLogo()">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                  <div (click)="associationLogoFile.click()">
                    <mat-icon *ngIf="!associationLogoBlob && !form.value.associateLogo"
                              class="add-logo"
                              [inline]="true"> add_photo_alternate </mat-icon>
                    <canvas id="association-logo-canvas"
                            class="photo"
                            hidden></canvas>
                    <img *ngIf="!associationLogoBlob && form.value.associateLogo"
                        class="photo"
                        [src]="form.value.associateLogo" />
                    <input #associationLogoFile
                          hidden
                          type="file"
                          accept="image/*"
                          formControlName="associationLogoInput"
                          (click)="form.get('associationLogoInput').setValue('')"
                          (change)="onChangeAssociationLogo($event)" />
                  </div>
                </div>
              </div>
  
            </div>
          </div>
        </ng-container>
    
      </div>
      <div class="d-flex justify-content-end align-items-center gap-1">
        <button aria-label="cancel"
                mat-raised-button
                color="accent"
                type="button"
                (click)="goBack()">
          {{ 'ACTION.SHARED.CANCEL' | translate }}
        </button>
        <button aria-label="save"
                mat-raised-button
                color="primary"
                type="button"
                (singleClick)="onSubmit()"
                [disabled]="form.invalid || form.pristine">
          {{ 'ACTION.SHARED.SAVE_CHANGES' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>