import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
// import { version } from '../../../../../package.json';
import whatsAppIcon from '../../../../assets/images/icons/whatsapp';
import { environment } from '../../../../environments/environment';
import { CompanyService } from '../../../service/company.service';
import { LayoutService } from '../../../service/layout.service';
import { MkgSpeedDialConfig } from '../../../shared/components/speed-dial/speed-dial.component';

@Component({
  selector: 'app-footer',
  //standalone: true,
  //imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {


  isMakena = environment.makena;
  isPO360 = environment.dota;
  // APP_VERSION = version;
  APP_NAME: typeof environment.envName = environment.envName;
  showSupport = ['MekanicWEB', 'MekanicAGRO', 'PO360'].includes(this.APP_NAME);
  invoiceSpeedDialConfig: MkgSpeedDialConfig = {
    direction: 'top',
    triggerButton: {
      icon: "add",
      matColor: "accent"
    },
    buttons: [{
      label: "NFe",
      matColor: "primary",
      onClick: () => this.layout.newInvoice('NFe')
    },
    {
      label: "NFCe",
      matColor: "primary",
      onClick: () => this.layout.newInvoice('NFCe')
    },
    {
      label: "NFSe",
      matColor: "primary",
      onClick: () => this.layout.newInvoice('NFSe')
    },
    {
      label: "OIP",
      matColor: "primary",
      onClick: () => this.layout.newInvoice('OIP')
    },
    {
      label: "OIS",
      matColor: "primary",
      onClick: () => this.layout.newInvoice('OIS')
    }]
  }

  constructor(
    public layout: LayoutService,
    public companyService: CompanyService,
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
  ) {
  }

  ngOnInit() {
    this.iconRegistry.addSvgIconLiteral('whatsapp', this.sanitizer.bypassSecurityTrustHtml(whatsAppIcon));
  }

  
}
// export class FooterComponent implements OnInit {


//   isMakena = environment.makena;
//   APP_VERSION = '2.0.0' //version;
//   APP_NAME: typeof environment.envName = environment.envName;
//   showSupport = ['MekanicWEB', 'MekanicAGRO', 'PO360'].includes(this.APP_NAME)

//   constructor(
//     // public layout: LayoutService,
//     // public companyService: CompanyService,
//     private sanitizer: DomSanitizer,
//     private iconRegistry: MatIconRegistry,
//   ) {
//   }

//   ngOnInit() {
//     this.iconRegistry.addSvgIconLiteral('whatsapp', this.sanitizer.bypassSecurityTrustHtml(whatsAppIcon));
//   }


//   public support() {
//     if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
//       window.open('https://api.whatsapp.com/send?phone=554599800231&amp;text=Olá, estou entrando em contato pelo site', '_blank')
//     } else {
//       window.open('https://web.whatsapp.com/send?phone=554599800231&amp;text=Olá, estou entrando em contato pelo site', '_blank')
//     }
//   }

// }
