<div class="t5-msg-dialog">
  <h2 mat-dialog-title>{{data.title}}</h2>

  <mat-dialog-content>
    <div class="message-content">
      <p *ngIf="data.description && data.description !== ''">{{data.description}}</p>

      <div class="image-container" *ngIf="data.image && data.image !== ''">
        <img [src]="data.image" alt="Message image" class="message-image" (click)="showFullImage()">
      </div>

      <div class="link-container" *ngIf="data.link && data.link !== ''">
        <a [href]="data.link" target="_blank" rel="noopener noreferrer">{{ data.link}}</a>
      </div>

      <small class="posted-time">{{data.createdAt | date:'mediumDate'}}</small>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button (click)="close()">Fechar</button>
  </mat-dialog-actions>
</div>

<div class="fullscreen-image-overlay" *ngIf="isFullImageVisible" (click)="hideFullImage()">
  <div class="close-button" (click)="hideFullImage()">
    <mat-icon>close</mat-icon>
  </div>
  <div class="image-wrapper">
    <img [src]="data.image" alt="Fullscreen image">
  </div>
</div>
