import { LOCALE_ID, NgModule, importProvidersFrom, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { AppRoutingModule } from './app-routing.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './component/other/footer/footer.component';

// import { AngularFireModule } from '@angular/fire';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFirestoreModule } from '@angular/fire/firestore';
// import { AngularFireStorageModule } from '@angular/fire/storage';
// import { AngularFireDatabaseModule } from 'angularfire2/database';

import { environment } from '../environments/environment';

/** Material imports */
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppComponent } from './app.component';
import { HeaderComponent } from './component/other/header/header.component';

// Firebase
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MatMomentDateModule, MomentDateAdapter, provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { KanbanModule } from 'smart-webcomponents-angular/kanban';
import { AddChecklistModelComponent } from './component/dialog/add-checklist-model/add-checklist-model.component';
import { AddChecklistQuestionComponent } from './component/dialog/add-checklist-question/add-checklist-question.component';
import { ApplicationEditComponent } from './component/dialog/application-edit/application-edit.component';
import { AskRedirectComponent } from './component/dialog/ask-redirect/ask-redirect.component';
import { BankEditComponent } from './component/dialog/bank-edit/bank-edit.component';
import { CashierEventTypeEditComponent } from './component/dialog/cashier-event-type-edit/cashier-event-type-edit.component';
import { ChooseCardAdmComponent } from './component/dialog/choose-card-adm/choose-card-adm.component';
import { ConfirmationDialogComponent } from './component/dialog/confirmation-dialog/confirmation-dialog.component';
import { CustomStateBudgetEditComponent } from './component/dialog/custom-state-budget-edit/custom-state-budget-edit.component';
import { CustomStateEditComponent } from './component/dialog/custom-state-edit/custom-state-edit.component';
import { ExpenseEditComponent } from './component/dialog/expense-edit/expense-edit.component';
import { GroupEditComponent } from './component/dialog/group-edit/group-edit.component';
import { InfoComponentComponent } from './component/dialog/info-component/info-component.component';
import { OperationWithoutExpensetypeComponent } from './component/dialog/operation-without-expensetype/operation-without-expensetype.component';
import { OperationWithoutTitletypeComponent } from './component/dialog/operation-without-titletype/operation-without-titletype.component';
import { PacketEditComponent } from './component/dialog/packet-edit/packet-edit.component';
import { PartBrandEditComponent } from './component/dialog/part-brand-edit/part-brand-edit.component';
import { PaymentMethodEditComponent } from './component/dialog/payment-method-edit/payment-method-edit.component';
import { PhotoEditComponent } from './component/dialog/photo-edit/photo-edit.component';
import { PreEditorComponent } from './component/dialog/pre-editor/pre-editor.component';
import { RoScheduleComponent } from './component/dialog/ro-schedule/ro-schedule.component';
import { RoTypeEditComponent } from './component/dialog/ro-type-edit/ro-type-edit.component';
import { ServiceTypeEditComponent } from './component/dialog/service-type-edit/service-type-edit.component';
import { SimilarEditComponent } from './component/dialog/similar-edit/similar-edit.component';
import { SubgroupEditComponent } from './component/dialog/subgroup-edit/subgroup-edit.component';
import { TitleTypeEditComponent } from './component/dialog/title-type-edit/title-type-edit.component';
import { LoadingComponent } from './component/other/loading/loading.component';
import { QuickAccessComponent } from './component/other/quick-access/quick-access.component';

import { TokenInterceptor } from './interceptors/token-interceptor';
import { RequestInterceptor } from './interceptors/request-interceptor';
import { AddressService } from './service/address.service';

import { LocalStorageService } from './service/local.storage.service';

import { ComponentsModule } from './shared/components/components.module';
import { DirectivesModule } from './shared/directives/directives.module';
import { PipesModule } from './shared/pipes/pipes.module';

import 'moment/locale/pt';
import { PaginatorIntl } from './class/paginator-intl';
import { GroupByExpanseTypeEditComponent } from './component/dialog/group-by-expanse-type-edit/group-by-expanse-type-edit.component';
import { SnackbarComponent } from './component/other/snackbar/snackbar.component';
import { FirebaseResetPasswordComponent } from './component/page/firebase-reset-password/firebase-reset-password.component';
import { FirebaseSignInComponent } from './component/page/firebase-sign-in/firebase-sign-in.component';
import { FirebaseSignUpComponent } from './component/page/firebase-sign-up/firebase-sign-up.component';
import { UserProfileComponent } from './component/page/user-profile/user-profile.component';
import { ClientService } from './service/client.service';
import { CompanyService } from './service/company.service';
import { LaborService } from './service/labor.service';
import { SupplierService } from './service/supplier.service';
import { UserService } from './service/user.service';
import { VehicleService } from './service/vehicle.service';
import { SubgroupByExpenseTypeEditComponent } from './component/dialog/subgroup-by-expense-type-edit/subgroup-by-expense-type-edit.component';
import { VehicleBreakpointPartNameComponent } from './component/dialog/vehicle-breakpoint-part-name/vehicle-breakpoint-part-name.component';
import { ProfessionEditCreateComponent } from './component/dialog/profession-edit-create/profession-edit-create.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ControlMessageEditComponent } from './component/dialog/control-message-edit/control-message-edit.component';
import { AddPartRepeatedComponent } from './component/dialog/add-part-repeated/add-part-repeated.component';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { EmojiComponent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

import { AddCloneRoComponent } from './component/dialog/add-clone-ro/add-clone-ro.component';
import { ValidateNcmBeforeClosingOsComponent } from './component/dialog/validate-ncm-before-closing-os/validate-ncm-before-closing-os.component';
import { InputModule } from 'smart-webcomponents-angular/input';
import { SnackbarService } from './service/snackbar.service';
import { AccessDeniedComponent } from './component/dialog/access-denied/access-denied.component';
import { PartsNotFoundByXlsImportComponent } from './component/dialog/parts-not-found-by-xls-import/parts-not-found-by-xls-import.component';
import { CompanyDetailComponent } from './component/other/company-detail/company-detail.component';
import { CantCancelInvoiceWarningComponent } from './component/dialog/cant-cancel-invoice-warning/cant-cancel-invoice-warning.component';
registerLocaleData(localePt);



// import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
// import { PaginatorIntl } from './class/paginator-intl';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';

const dialogs = [
  ConfirmationDialogComponent,
  PhotoEditComponent,
  RoScheduleComponent,
  AddPartRepeatedComponent,
  RoTypeEditComponent,
  PaymentMethodEditComponent,
  ServiceTypeEditComponent,
  OperationWithoutExpensetypeComponent,
  OperationWithoutTitletypeComponent,
  PartBrandEditComponent,
  ChooseCardAdmComponent,
  ApplicationEditComponent,
  GroupEditComponent,
  SubgroupEditComponent,
  SimilarEditComponent,
  TitleTypeEditComponent,
  ExpenseEditComponent,
  InfoComponentComponent,
  AskRedirectComponent,
  BankEditComponent,
  ValidateNcmBeforeClosingOsComponent,
  CashierEventTypeEditComponent,
  CustomStateEditComponent,
  AddCloneRoComponent,
  AddChecklistModelComponent,
  AddChecklistQuestionComponent,
  PacketEditComponent,
  CustomStateBudgetEditComponent,
  PreEditorComponent,
  ControlMessageEditComponent,
  GroupByExpanseTypeEditComponent,
  SubgroupByExpenseTypeEditComponent,
  VehicleBreakpointPartNameComponent,
  ProfessionEditCreateComponent,
  CantCancelInvoiceWarningComponent
];

const miscComponents = [
  FooterComponent,
  HeaderComponent,
  LoadingComponent,
  SnackbarComponent,
  CompanyDetailComponent
];

const services = [
  AddressService,
  ClientService,
  CompanyService,
  LaborService,
  // LowService,
  // MessageService,
  // PartService,
  // PdfChecklistService,
  // RoLaborService,
  // RoPartService,
  // RoTypeService,
  // RoService,
  // ServiceTypeService,
  SupplierService,
  UserService,
  VehicleService,
  SnackbarService
  // ConciliationService,
  // AppointmentService,
  // DataCacheService,
  // OrderService
];

// required for AoT
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// export const appConfig: ApplicationConfig = {
//   providers: [provideAnimations(), provideHttpClient(),
//     TranslateModule.forRoot({
//       defaultLanguage: 'en',
//       loader: {
//         provide: TranslateLoader,
//         useFactory: HttpLoaderFactory,
//         deps: [HttpClient]
//       }
//     }).providers!]
// };

// new
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localePt);


@NgModule({
  declarations: [
    AppComponent,
    // pages,
    dialogs,
    miscComponents,
    FirebaseSignInComponent,
    FirebaseResetPasswordComponent,
    FirebaseSignUpComponent,
    UserProfileComponent,
    // formatters,
    QuickAccessComponent,
    AccessDeniedComponent,
    PartsNotFoundByXlsImportComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFirestoreModule,

    FormsModule,
    HttpClientModule,
    PipesModule,
    ComponentsModule,
    CommonModule,
    DirectivesModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    KanbanModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    InputModule,
    MatSnackBarModule,
    MatSortModule,
    MatTabsModule,
    MatTableModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    EmojiModule,
    PickerModule,
    EmojiComponent,
    MatTooltipModule,
    // ScrollingModule,
    DragDropModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
    // TextMaskModule,
    // SmdFabSpeedDialModule,
    NgxSliderModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
    //     deps: [HttpClient]
    //   }
    // }),
    // ServiceWorkerModule.register('/firebase-messaging-sw.js', { enabled: false }),
    // ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }) // PWA

    // new


    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'pt',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      // isolate: true
    })
  ],
  providers: [
    CurrencyPipe,
    services,
    LocalStorageService,
    NgxImageCompressService,
    TranslateService,
    importProvidersFrom(
      provideFirebaseApp(() => initializeApp(environment.firebase)) as any,
      provideAuth(() => getAuth()) as any,
      provideFirestore(() => getFirestore()) as any
    ),
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    // { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    provideMomentDateAdapter(),
    {
      provide: MatPaginatorIntl,
      useFactory: (translate: TranslateService) => new PaginatorIntl(translate),
      deps: [TranslateService]
    },
    // provideClientHydration()
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {

  constructor() {

    if (window['Smart']) {
      Object.defineProperty(window['Smart'], "License", {
        value: environment.smartUI
      });
    } else {
      Object.defineProperty(window, "Smart", {
        value: { License: environment.smartUI }
      });
    }

  }

}
