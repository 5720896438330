import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FirebaseSignInComponent } from './component/page/firebase-sign-in/firebase-sign-in.component';
import { AuthGuard } from './guard/auth-guard.service';
import { UserProfileComponent } from './component/page/user-profile/user-profile.component';
import { FirebaseSignUpComponent } from './component/page/firebase-sign-up/firebase-sign-up.component';
import { FirebaseResetPasswordComponent } from './component/page/firebase-reset-password/firebase-reset-password.component';
import { CompanyDetailComponent } from './component/other/company-detail/company-detail.component';

const routes: Routes = [
  { path: 'edit/company/:company', component: CompanyDetailComponent },
  { path: 'new/company', component: CompanyDetailComponent },
  {
    path: 'sign-in',
    component: FirebaseSignInComponent
  },
  {
    path: 'sign-up',
    component: FirebaseSignUpComponent
  },
  {
    path: 'reset-password',
    component: FirebaseResetPasswordComponent
  },
  {
    path: 'user',
    component: UserProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company',
    loadChildren: () => import('./component/page/company/company.module').then(m => m.CompanyModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'sign-in'
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
