<!-- mobile view -->
<ng-container *ngIf="(layout.isHandset$ | async); else desktopTemplate">
  <mat-toolbar color="primary"
               class="mobile toolbar-right">
    <button aria-label="back"
            *ngIf="layout.component !== 'company'"
            mat-icon-button
            class="toolbar-button"
            [routerLink]="['/company']">
      <mat-icon>domain</mat-icon>
    </button>
    <div *ngIf="(dataService.company$ | async)"
         class="companyInfo">
      <div>
        {{ (dataService.company$ | async)?.fancyName || (dataService.company$ | async)?.socialName }}
      </div>
      <div>
        {{ 'PROPERTY.COMPANY.CNPJ' | translate }}: <span>{{ (dataService.company$ | async)?.cnpj }}</span>
      </div>
    </div>

    <div class="flex-1"></div>
    <div *ngIf="(dataService.user$ | async)"
         class="justify-self-end">
      <button mat-icon-button
              *ngIf="(layout.isHandset$ | async)"
              aria-label="support"
              title="Fale conosco"
              (click)="layout.support()">
        <mat-icon svgIcon="whatsapp"></mat-icon>
      </button>
      <button aria-label="user"
              class="user-info"
              mat-button
              [matMenuTriggerFor]="userMenu">
        <mat-icon *ngIf="!(dataService.user$ | async).photo">account_circle</mat-icon>
        <img *ngIf="(dataService.user$ | async).photo"
             class="user-photo"
             [src]="(dataService.user$ | async).photo" />
        {{ (dataService.user$ | async).firstName }}
      </button>
      <mat-menu #userMenu>
        <a mat-menu-item
           [routerLink]="['user']">
          {{ 'label_user_info' | translate }}
        </a>
        <button aria-label="logout"
                mat-menu-item
                (click)="signOut()">
          {{ 'label_logout' | translate }}
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>
</ng-container>

<!-- desktop view -->
<ng-template #desktopTemplate>
  <mat-toolbar color="primary"
               class="toolbar">
    <button aria-label="back"
            *ngIf="['user', 'blocked'].includes(layout.component)"
            mat-icon-button
            class="toolbar-button"
            (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <button aria-label="toggle"
            *ngIf="(dataService.company$ | async)?.currentState != companyStates.blocked.id"
            mat-icon-button
            class="toolbar-button"
            (click)="layout.toggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>

    <ng-container [ngSwitch]="APP_NAME">
      <img *ngSwitchCase="'Makeasy'"
           class="logo makena"
           src="/assets/images/makena_logos/MAKEASY.png"
           alt="Logo Makena" />
      <img *ngSwitchCase="'PO360'"
           class="logo PO360"
           src="assets/PO360/Logomarca/PNG/HORIZ. Claro Principal.png"
           alt="Logo PO360" />
      <img *ngSwitchCase="'MekanicAGRO'"
           class="logo agro"
           src="/assets/images/LogoMekanicAgro.png"
           alt="Logo MekanicWeb" />
      <img *ngSwitchDefault
           class="logo"
           src="/assets/images/LogoMekanicWeb.png"
           alt="Logo MekanicWeb" />
    </ng-container>

    @if(dataService.user && layout.component !== "company"){
    <button mat-icon-button
            [matMenuTriggerFor]="companies"><mat-icon>domain</mat-icon></button>
    <mat-menu #companies="matMenu"
              yPosition="below">
      @for(company of (dataService.user$ | async)?.ownCompanies$ | async; track $index){
      @if(company.currentState !== companyStates.blocked.id){
      <button mat-menu-item
              [disabled]="company.id === (dataService.company$ | async)?.id"
              (click)="changeCompany(company)">
        <div>{{ company.fancyName }}</div>
        <small>{{ company.cnpj | document }}</small>
      </button>
      <mat-divider></mat-divider>
      }
      }
      @for(company of (dataService.user$ | async)?.linkedCompanies$ | async; track $index){
      @if(company.currentState !== companyStates.blocked.id){
      <button mat-menu-item
              [disabled]="company.id === (dataService.company$ | async)?.id"
              (click)="changeCompany(company)">
        <div>{{ company.fancyName }}</div>
        <small>{{ company.cnpj | document }}</small>
      </button>
      <mat-divider></mat-divider>
      }
      }
    </mat-menu>
    }

    <div class="toolbar-right">

      <span class="companyInfo"
            *ngIf="(dataService.company$ | async)">
        <small>{{ (dataService.company$ | async)?.fancyName || (dataService.company$ | async)?.socialName }}</small>
        <small>
          {{ 'PROPERTY.COMPANY.CNPJ' | translate }}:
          <span>{{ (dataService.company$ | async)?.cnpj }}</span>
        </small>
      </span>


      @if(dataService.user){
      <button aria-label="messages"
              *ngIf="(dataService.company$ | async)?.currentState != companyStates.blocked.id
            && (dataService.userRole$ | async) != roles.mechanic.id"
              mat-icon-button
              (click)="toggleMessages()">
        <mat-icon matBadgeColor="warn"
                  aria-hidden="false"
                  matBadgeDescription="unreaded messages"
                  [matBadge]="unread ? (unread < 99 ? unread : 99) : ''"> notifications </mat-icon>
      </button>
      }

      <div *ngIf="(dataService.user$ | async)">
        <button aria-label="user"
                class="user-info"
                mat-button
                [matMenuTriggerFor]="userMenu">
          <mat-icon *ngIf="!(dataService.user$ | async).photo">account_circle</mat-icon>
          <img *ngIf="(dataService.user$ | async).photo"
               class="user-photo"
               [src]="(dataService.user$ | async).photo" />
          {{ (dataService.user$ | async).firstName }}
        </button>
        <mat-menu #userMenu>
          <a mat-menu-item
             [routerLink]="['user']">
            {{ 'label_user_info' | translate }}
          </a>
          <button aria-label="logout"
                  mat-menu-item
                  (click)="signOut()">
            {{ 'label_logout' | translate }}
          </button>
        </mat-menu>
      </div>

    </div>

  </mat-toolbar>
</ng-template>


<mat-sidenav-container [ngClass]="{
  'sidenav-container': true,
  'mobile':(layout.isHandset$ | async),
  'desktop': !(layout.isHandset$ | async),
  'makena': isMakena,
  'PO360': isPO360
}"
                       autosize>
  <!-- left sidenav -->
  <mat-sidenav *ngIf="!(layout.isHandset$ | async) && (dataService.company$ | async) && (dataService.company$ | async).currentState != companyStates.blocked.id"
               #leftDrawer
               id="company-menu"
               [fixedTopGap]="50"
               [fixedBottomGap]="isMakena || isPO360 ? 0 : 50"
               fixedInViewport="true"
               role="navigation"
               [mode]="'side'"
               [opened]="true"
               position="start">
    <mat-nav-list (click)="resetCache()">

      <ng-container *ngFor="let item of menu">
        <a mat-list-item
           (click)="navigateOrExpand(item)"
           *ngIf="
           (!item.inDevelopment || !isProduction) &&
           (
            (
              !item.blockedRoles?.includes((dataService.userRole$ | async)) 
              && 
              !(dataService.accessProfile$ | async)
            ) 
            || 
            (
              (dataService.accessProfile$ | async)
              &&
              ((dataService.accessProfile$ | async) | canAccess: item.profileModule : true)
            )
           )"
           [title]="layout.menu.sidenav ? '' : (('MENU.' + item?.component) | translate)"
           [ngClass]="{ 'selected' : (layout.component | menuSelected : item : layout.menu.sidenav : layout.menu[item.component] ) }">
          <mat-icon matListItemIcon
                    *ngIf="item.icon">{{ item.icon }}</mat-icon>
          <mat-icon matListItemIcon
                    *ngIf="item.svgIcon"
                    style="display: block;"
                    [svgIcon]="item.svgIcon"></mat-icon>
          <h3 matListItemTitle
              *ngIf="layout.menu.sidenav">{{ ('MENU.' + item.component) | translate }}</h3>
          <mat-icon *ngIf="item.childs && layout.menu.sidenav"
                    style="align-self: center !important;">
            {{ layout.menu[item.component] ? 'expand_more' : 'expand_less' }}
          </mat-icon>
        </a>

        <ng-container *ngIf="
                              (
                                (
                                  !item.blockedRoles?.includes((dataService.userRole$ | async)) 
                                  && 
                                  !(dataService.accessProfile$ | async)
                                ) 
                                || 
                                (
                                  (dataService.accessProfile$ | async)
                                  &&
                                  ((dataService.accessProfile$ | async) | canAccess: item.profileModule : true)
                                )
                              )
                              && layout.menu[item.component]
                              && item.childs
                              && (!item.inDevelopment || !isProduction)">
          <ng-container *ngFor="let child of item.childs">
            @if(dataService.accessProfile$ | async | canAccess: child.profileRule){
              <a [routerLink]="child.href">
                <mat-list-item
                              [ngClass]="{ 'sub-item': true, 'selected': (layout.component === child.component || child.shadows?.includes(layout.component)) }">
                  <h3 matListItemTitle> {{ ('MENU.' + child.component) | translate }} </h3>
                </mat-list-item>
              </a>
            }
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- labors -->
      <!-- <a *ngIf="user?.role != roles.mechanic.id"
[routerLink]="['company', (dataService.company$ | async).id, 'labors']">
<mat-list-item [class.selected]="layout.component == 'labors'">
<mat-icon matListItemIcon
style="display: block;"
svgIcon="labor"></mat-icon>
<h3 matLine
*ngIf="layout.sidenav">{{ 'OBJECT.LABORS' | translate }}</h3>
</mat-list-item>
</a> -->


      <!-- users -->
      <!-- <a *ngIf="user?.role == roles.admin.id"
[routerLink]="['company', (dataService.company$ | async).id, 'users']">
<mat-list-item [class.selected]="layout.component == 'users'">
<mat-icon matListItemIcon
[matBadge]="layout.newUsers"
[matBadgeHidden]="layout.newUsers == 0"
matBadgeColor="warn">people</mat-icon>
<h3 matLine
*ngIf="layout.sidenav">
{{ 'OBJECT.USERS' | translate }}
</h3>
</mat-list-item>
</a> -->

    </mat-nav-list>

  </mat-sidenav>


  <mat-sidenav-content id="content" [@routerTransition]="prepareRoute(o)">
  <!-- <mat-sidenav-content id="content" [@routerTransition]="o.isActivated ? o.activatedRoute : ''"> -->
    <router-outlet #o="outlet"></router-outlet>
    <div *ngIf="(layout.isHandset$ | async)"
         class="mobileSpacer">
    </div>
  </mat-sidenav-content>

  <!-- right sidenav -->
  <mat-sidenav #rightDrawer
               [fixedTopGap]="50"
               fixedInViewport="true"
               mode="over"
               position="end">
    <mat-nav-list *ngIf="(dataService.company$ | async)">
      <span class="noMessages"
            *ngIf="messages?.length == 0">{{
        "label_no_messages" | translate
        }}</span>
      <div class="readAll"
           *ngIf="messages?.length > 0 && unread > 0">
        <button mat-button
                color="primary"
                (click)="readAllMessages()">
          Ler Todas
        </button>
      </div>
      <mat-list-item class="py-4 "
                     *ngFor="let message of messages"
                     (click)="openMessage(message)">
        <div class="d-flex">
          <mat-icon *ngIf="!message.read"
                    mat-list-icon>markunread</mat-icon>
          <mat-icon *ngIf="message.read"
                    mat-list-icon>drafts</mat-icon>
          <div class="pt-3">
            <h4 *ngIf="message.ro && message.ro.code"
                mat-line>
              <span *ngIf="message.type == messageTypes.authorized.id">
                {{ "NOTIFICATION.RO_AUTHORIZED" | translate : { code: message.ro.code } }}
              </span>
              <span *ngIf="message.type == messageTypes.rejected.id">
                {{ "NOTIFICATION.RO_REJECTED" | translate : { code: message.ro.code } }}
              </span>
              <span *ngIf="message.type == messageTypes.evaluated.id">
                {{ "NOTIFICATION.RO_EVALUATED" | translate : { code: message.ro.code } }}
              </span>
            </h4>
            <h4 *ngIf="message.ro && !message.ro.code"
                mat-line>
              <span *ngIf="message.type == messageTypes.authorized.id">
                {{ "NOTIFICATION.RO_AUTHORIZED_NOT_INTEGRATED" | translate }}
              </span>
              <span *ngIf="message.type == messageTypes.rejected.id">
                {{ "NOTIFICATION.RO_REJECTED_NOT_INTEGRATED" | translate }}
              </span>
              <span *ngIf="message.type == messageTypes.evaluated.id">
                {{ "NOTIFICATION.RO_EVALUATED_NOT_INTEGRATED" | translate }}
              </span>
            </h4>
            <p mat-line>
              {{ message.createdAt | date }}
            </p>
          </div>
        </div>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>

  <button (click)="ativaSnack()">clica aqui pra aparecer</button>
</mat-sidenav-container>