<mat-nav-list *ngIf="(dataService.company$ | async)">
  <span class="noMessages"
        *ngIf="messages?.length == 0">{{ "label_no_messages" | translate }}
  </span>
  <div class="readAll"
        *ngIf="messages?.length > 0 && unread > 0">
    <button mat-button
            color="primary"
            (click)="readAllMessages()">
      Ler Todas
    </button>

  </div>
  <mat-list-item
                  *ngFor="let message of messages"
                  (click)="openMessage(message)">
    <div class="d-flex">
      <mat-icon *ngIf="!message.read"
                mat-list-icon>markunread</mat-icon>
      <mat-icon *ngIf="message.read"
                mat-list-icon>drafts</mat-icon>
      <div class="pt-3">
        <h4 *ngIf="message.ro && message.ro.code"
            mat-line>
          <span *ngIf="message.type == messageTypes.authorized.id">
            {{ "NOTIFICATION.RO_AUTHORIZED" | translate : { code: message.ro.code } }}
          </span>
          <span *ngIf="message.type == messageTypes.rejected.id">
            {{ "NOTIFICATION.RO_REJECTED" | translate : { code: message.ro.code } }}
          </span>
          <span *ngIf="message.type == messageTypes.evaluated.id">
            {{ "NOTIFICATION.RO_EVALUATED" | translate : { code: message.ro.code } }}
          </span>
        </h4>
        <h4 *ngIf="message.ro && !message.ro.code"
            mat-line>
          <span *ngIf="message.type == messageTypes.authorized.id">
            {{ "NOTIFICATION.RO_AUTHORIZED_NOT_INTEGRATED" | translate }}
          </span>
          <span *ngIf="message.type == messageTypes.rejected.id">
            {{ "NOTIFICATION.RO_REJECTED_NOT_INTEGRATED" | translate }}
          </span>
          <span *ngIf="message.type == messageTypes.evaluated.id">
            {{ "NOTIFICATION.RO_EVALUATED_NOT_INTEGRATED" | translate }}
          </span>
        </h4>
        <p mat-line>
          {{ message.createdAt | date }}
        </p>
      </div>
    </div>
  </mat-list-item>
</mat-nav-list>
