<div class="mkg-frontpanel">

  <div class="d-flex justify-content-between align-items-center px-4">
    @if(companyData){
    <h1> {{ 'ACTION.COMPANY.UPDATE' | translate }} </h1>
    } @else {
    <h1> {{ 'ACTION.COMPANY.ADD' | translate }} </h1>
    <mat-slide-toggle color="primary"
                      [formControl]="isTcarIntegrated">
      Integrada com Tcar
    </mat-slide-toggle>
    }
  </div>

  <div class="main-container">
      <div class="form-content">

      <mat-tab-group preserveContent>
        <mat-tab label="Identificação">
          <ng-template matTabContent>
            <form class="py-2" [formGroup]="identifycationForm">
              <div class="row mx-0 w-100">
                <!-- Social name -->
                <mat-form-field [ngClass]="['col-12', 'col-md-6', isTcarIntegrated.value ? 'col-lg-4' : 'col-lg-3']">
                  <mat-label>{{ 'PROPERTY.COMPANY.SOCIAL_NAME' | translate }}</mat-label>
                  <input matInput
                          autocomplete="off"
                          maxlength="60"
                          formControlName="socialName" />
                  <mat-error *ngIf="identifycationForm.get('socialName').errors">
                    {{ identifycationForm.get('socialName').errors | error | translate : identifycationForm.get('socialName').errors }}
                  </mat-error>
                </mat-form-field>

                <!-- Fancy name -->
                <mat-form-field [ngClass]="['col-12', 'col-md-6', isTcarIntegrated.value ? 'col-lg-4' : 'col-lg-3']">
                  <mat-label>{{ 'PROPERTY.COMPANY.FANCY_NAME' | translate }}</mat-label>
                  <input matInput
                          autocomplete="off"
                          maxlength="60"
                          formControlName="fancyName" />
                  <mat-error *ngIf="identifycationForm.get('fancyName').errors">
                    {{ identifycationForm.get('fancyName').errors | error | translate : identifycationForm.get('fancyName').errors }}
                  </mat-error>
                </mat-form-field>

                <!-- CNPJ -->
                <mat-form-field [ngClass]="['col-12', 'col-md-6', isTcarIntegrated.value ? 'col-lg-4' : 'col-lg-3']">
                  <mat-label>{{ 'PROPERTY.COMPANY.CNPJ' | translate }}</mat-label>
                  <input matInput
                          autocomplete="off"
                          cnpj
                          maxlength="18"
                          formControlName="cnpj" />
                  <mat-error *ngIf="identifycationForm.get('cnpj').errors">
                    {{ identifycationForm.get('cnpj').errors | error | translate: identifycationForm.get('cnpj').errors }}
                  </mat-error>
                  <mat-hint *ngIf="identifycationForm.hasError('controlsEquals')">
                    CNPJ e CNPJ-Matriz precisam ser diferentes
                  </mat-hint>
                  <mat-hint *ngIf="!companyData && isTcarIntegrated.value && !identifycationForm.get('cnpj').errors">
                    <a mat-button
                        color="primary"
                        (click)="generateIntegrationKey()">
                      <mat-icon>vpn_key</mat-icon>
                      Gerar chave de integração
                    </a>
                  </mat-hint>
                </mat-form-field>

                <!-- CNPJ matrix -->
                @if(!isTcarIntegrated.value){
                <mat-form-field class="col-12 col-md-6 col-lg-3">
                  <mat-label>CNPJ Matriz</mat-label>
                  <input matInput
                          autocomplete="off"
                          cnpj
                          maxlength="18"
                          formControlName="cnpjMatrix" />
                  <mat-error *ngIf="identifycationForm.get('cnpjMatrix').errors">
                    {{ identifycationForm.get('cnpjMatrix').errors | error | translate : identifycationForm.get('cnpjMatrix').errors }}
                  </mat-error>
                  <mat-hint *ngIf="identifycationForm.hasError('controlsEquals')">
                    CNPJ e CNPJ-Matriz precisam ser diferentes
                  </mat-hint>
                </mat-form-field>
                }
              </div>

              @if(identifycationForm.value.id) {
                <div class="row mx-0 w-100 justify-content-end">
                  <div class="col-sm-12 col-md-6 col-lg-4">

                    <div class="txt-center">
                      <p>{{ 'PROPERTY.COMPANY.COMPANY_LOGO' | translate }}</p>

                      @if(companyLogoBlob || identifycationForm.value.companyLogo){
                        <div class="d-flex justify-content-end gap-05 mb-1">
                          <button mat-stroked-button
                                  (click)="companyLogoFile.click()"
                                  color="primary">
                            <mat-icon> add_photo_alternate </mat-icon>
                              Alterar
                          </button>
                          <button mat-stroked-button
                                  (click)="deleteCompanyLogo()"
                                  color="warn">
                            <mat-icon>delete</mat-icon>
                              Remover
                          </button>
                        </div>
                      } @else {
                        <button mat-stroked-button
                                (click)="companyLogoFile.click()"
                                color="primary"
                                class="d-block ms-auto ml-auto">
                          <mat-icon> add_photo_alternate </mat-icon>
                          Adicionar
                        </button>
                      }
                      
                      <img *ngIf="!companyLogoBlob && identifycationForm.value.companyLogo"
                            class="photo"
                            [src]="identifycationForm.value.companyLogo" />

                      <canvas id="company-logo-canvas"
                              class="photo"
                              hidden></canvas>
                      <input #companyLogoFile
                              hidden
                              type="file"
                              accept="image/*"
                              formControlName="companyLogoInput"
                              (click)="identifycationForm.get('companyLogoInput').setValue('')"
                              (change)="onChangeCompanyLogo($event)" />
                    </div>

                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-4">
                    <div class="txt-center">
                      <p>{{ 'PROPERTY.COMPANY.ASSOCIATION_LOGO' | translate }}</p>
                      @if(associationLogoBlob || identifycationForm.value.associateLogo){
                        <div class="d-flex justify-content-end gap-05 mb-1">
                          <button mat-stroked-button
                                  (click)="associationLogoFile.click()"
                                  color="primary">
                            <mat-icon> add_photo_alternate </mat-icon>
                              Alterar
                          </button>
                          <button mat-stroked-button
                          (click)="deleteAssociationLogo()"
                          color="warn">
                          <mat-icon>delete</mat-icon>
                              Remover
                          </button>
                        </div>
                        } @else {
                          <button mat-stroked-button
                                  (click)="associationLogoFile.click()"
                                  color="primary"
                                  class="d-block ms-auto ml-auto">
                            <mat-icon> add_photo_alternate </mat-icon>
                            Adicionar
                          </button>
                        }

                      <img *ngIf="!associationLogoBlob && identifycationForm.value.associateLogo"
                            class="photo"
                            [src]="identifycationForm.value.associateLogo" />

                      <canvas id="association-logo-canvas"
                              class="photo"
                              hidden></canvas>

                      <input #associationLogoFile
                              hidden
                              type="file"
                              accept="image/*"
                              formControlName="associationLogoInput"
                              (click)="identifycationForm.get('associationLogoInput').setValue('')"
                              (change)="onChangeAssociationLogo($event)" />
                    </div>
                  </div>
                </div>
              }
            </form>
          </ng-template>
        </mat-tab>

        <mat-tab label="Endereço">
          <ng-template matTabContent>
            <form [formGroup]="addressForm" class="row mx-0 w-100 py-2">
              <!-- CEP -->
              <mat-form-field class="col-12 col-md-6 col-lg-2">
                <mat-label>{{ 'PROPERTY.ADDRESS.CEP' | translate }}</mat-label>
                <input matInput
                       autocomplete="off"
                       formControlName="cep"
                       cep />
                <mat-error *ngIf="addressForm.get('cep').errors">
                  {{ addressForm.get('cep').errors | error | translate : addressForm.get('cep').errors }}
                </mat-error>
              </mat-form-field>

              <!-- Type -->
              <mat-form-field class="col-12 col-md-6 col-lg-2">
                <mat-label>{{ 'PROPERTY.ADDRESS.TYPE' | translate }}</mat-label>
                <input matInput
                        autocomplete="off"
                        formControlName="type"
                        maxlength="30"
                        placeholder="Ex: Rua, Avenida">
                <mat-error *ngIf="addressForm.get('type').errors">
                  {{ addressForm.get('type').errors | error | translate : addressForm.get('type').errors }}
                </mat-error>
              </mat-form-field>

              <!-- Street -->
              <mat-form-field class="col-12 col-md-6 col-lg-4">
                <mat-label>{{ 'PROPERTY.ADDRESS.STREET' | translate }}</mat-label>
                <input matInput
                        autocomplete="off"
                        minlength="2"
                        maxlength="60"
                        formControlName="street" />
                <mat-error *ngIf="addressForm.get('street').errors">
                  {{ addressForm.get('street').errors | error | translate : addressForm.get('street').errors }}
                </mat-error>
              </mat-form-field>

              <!-- Number -->
              <mat-form-field class="col-12 col-md-6 col-lg-2">
                <mat-label>{{ 'PROPERTY.ADDRESS.NUMBER' | translate }}</mat-label>
                <input matInput
                        autocomplete="off"
                        maxlength="60"
                        formControlName="number" />
                <mat-error *ngIf="addressForm.get('number').errors">
                  {{ addressForm.get('number').errors | error | translate : addressForm.get('number').errors }}
                </mat-error>
              </mat-form-field>

              <!-- Complement -->
              <mat-form-field class="col-12 col-md-6 col-lg-2">
                <mat-label>{{ 'PROPERTY.ADDRESS.COMPLEMENT' | translate }}</mat-label>
                <input matInput
                        autocomplete="off"
                        maxlength="60"
                        formControlName="complement" />
              </mat-form-field>

              <!-- neighborhood -->
              <mat-form-field class="col-12 col-lg-4">
                <mat-label>{{ 'PROPERTY.ADDRESS.NEIGHBORHOOD' | translate }}</mat-label>
                <input matInput
                        autocomplete="off"
                        maxlength="60"
                        formControlName="neighborhood" />
                <mat-error *ngIf="addressForm.get('neighborhood').errors">
                  {{ addressForm.get('neighborhood').errors | error | translate :
                  addressForm.get('neighborhood').errors
                  }}
                </mat-error>
              </mat-form-field>

              <!-- state -->
              <mat-form-field class="col-12 col-md-6 col-lg-3">
                <mat-label>{{ 'OBJECT.STATE' | translate }}</mat-label>
                <mat-select formControlName="state">
                  <mat-option *ngFor="let state of states"
                              [value]="state.id">
                    {{ state.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addressForm.get('state').errors">
                  {{ addressForm.get('state').errors | error | translate : addressForm.get('state').errors }}
                </mat-error>
              </mat-form-field>

              <!-- city -->
              <mat-form-field class="col-12 col-md-6 col-lg-3">
                <mat-label>{{ 'OBJECT.CITY' | translate }}</mat-label>
                <mat-select formControlName="city">
                  <mat-option *ngFor="let city of addressForm.value.state | address: states"
                              [value]="city.id">
                    {{ city.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addressForm.get('city').errors">
                  {{ addressForm.get('city').errors | error | translate : addressForm.get('city').errors }}
                </mat-error>
              </mat-form-field>
            </form>
          </ng-template>
        </mat-tab>

        <mat-tab label="Contato">
          <ng-template matTabContent>
            <form [formGroup]="contactForm" class="row mx-0 w-100 py-2">

              <!-- Phone 1 -->
              <mat-form-field class="col-12 col-md-6 col-lg-2">
                <mat-label>{{ 'PROPERTY.PERSON.PHONE' | translate }}</mat-label>
                <input matInput
                        autocomplete="off"
                        formControlName="phone"
                        minlength="10"
                        maxlength="17"
                        phone />
                <mat-hint *ngIf="isMakena">Para pesquisa de satisfção</mat-hint>
                <mat-error *ngIf="contactForm.get('phone').errors">
                  {{ contactForm.get('phone').errors | error | translate : contactForm.get('phone').errors }}
                </mat-error>
              </mat-form-field>

              <!-- Phone 2 -->
              <mat-form-field class="col-12 col-md-6 col-lg-2">
                <mat-label>{{ 'PROPERTY.PERSON.PHONE_2' | translate }}</mat-label>
                <input matInput
                        autocomplete="off"
                        formControlName="phone2"
                        maxlength="17"
                        phone />
                <mat-error *ngIf="contactForm.get('phone2').errors">
                  {{ contactForm.get('phone2').errors | error | translate : contactForm.get('phone2').errors }}
                </mat-error>
              </mat-form-field>

              <!-- Email -->
              <mat-form-field class="col-12 col-md-6 col-lg-4">
                <mat-label>{{ 'PROPERTY.PERSON.EMAIL' | translate }}</mat-label>
                <input matInput
                        type="email"
                        autocomplete="off"
                        maxlength="254"
                        formControlName="email" />
                <mat-error *ngIf="contactForm.get('email').errors">
                  {{ contactForm.get('email').errors | error | translate : contactForm.get('email').errors }}
                </mat-error>
              </mat-form-field>

              <!-- Site -->
              <mat-form-field class="col-12 col-md-6 col-lg-4">
                <mat-label>{{ 'PROPERTY.COMPANY.SITE' | translate }}</mat-label>
                <input matInput
                        type="text"
                        autocomplete="off"
                        maxlength="200"
                        formControlName="site" />
                <mat-error *ngIf="contactForm.get('site').errors">
                  {{ contactForm.get('site').errors | error | translate : contactForm.get('site').errors }}
                </mat-error>
              </mat-form-field>

              <!-- WhatsApp token -->
              <mat-form-field class="col-12 col-lg-4">
                <mat-label>TokenWhats</mat-label>
                <input matInput
                        autocomplete="off"
                        maxlength="60"
                        formControlName="tokenWhats" />
              </mat-form-field>
            </form>
          </ng-template>
        </mat-tab>

        @if (!isTcarIntegrated.value) {
          <mat-tab label="Fiscal">
            <ng-template matTabContent>
              <form [formGroup]="fiscalForm" class="row mx-0 w-100 py-2">
                <!-- 09.530-401 - 96.659.681/0001-73 -->
                <mat-checkbox color="primary"
                              class="col-12 col-md-6 col-lg-3"
                              [checked]="fiscalForm.value.migrateIntegrated"
                              formControlName="migrateIntegrated">
                  Emite documento fiscal
                </mat-checkbox>
  
                <mat-checkbox color="primary"
                              class="col-12 col-md-6 col-lg-3"
                              formControlName="deduNFE"
                              title="Se marcado, as notas de peças vão sofrer uma dedução de valor equivalente às retenções federais">
                  Dedução de peças
                </mat-checkbox>
                <mat-checkbox color="primary"
                              class="col-12 col-md-6 col-lg-3"
                              formControlName="deduNFSE"
                              title="Se marcado, as notas de serviços vão sofrer uma dedução de valor equivalente às retenções federais">
                  Dedução de serviços
                </mat-checkbox>
                <mat-checkbox color="primary"
                              class="col-12 col-md-6 col-lg-3"
                              formControlName="deduISS"
                              title="Se marcado, as notas de serviços vão sofrer uma dedução de valor referente ao ISS retido">
                  Dedução de ISS
                </mat-checkbox>
  
                @if(fiscalForm.value.migrateIntegrated) {
  
                  <!-- CRT -->
                  <mat-form-field class="col-12 col-md-6 col-lg-4">
                    <mat-label>{{ 'PROPERTY.COMPANY.CRT' | translate }}</mat-label>
                    <mat-select formControlName="crt">
                      <mat-option [value]="'1'">
                        Simples Nacional
                      </mat-option>
                      <mat-option [value]="'2'"
                                  title="Simples Nacional – excesso de sublimite de receita bruta">
                        Simples Nacional – excesso de sublimite de receita bruta
                      </mat-option>
                      <mat-option [value]="'3'">
                        Regime Normal
                      </mat-option>
                      <mat-option [value]="'4'"
                                  title="Simples Nacional – Microempreendedor Individual – MEI">
                        Simples Nacional – Microempreendedor Individual – MEI
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="fiscalForm.get('crt').errors">
                      {{ fiscalForm.get('crt').errors | error | translate : fiscalForm.get('crt').errors }}
                    </mat-error>
                  </mat-form-field>
  
  
                  <!-- State registration -->
                  <mat-form-field class="col-12 col-md-6 col-lg-2">
                    <mat-label>{{ 'PROPERTY.COMPANY.STATE_REGISTRATION' | translate }}</mat-label>
                    <input matInput
                          autocomplete="off"
                          maxlength="18"
                          formControlName="stateRegistration" />
                    <mat-error *ngIf="fiscalForm.get('stateRegistration').errors">
                      {{ fiscalForm.get('stateRegistration').errors | error | translate : fiscalForm.get('stateRegistration').errors
                      }}
                    </mat-error>
                  </mat-form-field>
  
                  <!-- City registration -->
                  <mat-form-field class="col-12 col-md-6 col-lg-2">
                    <mat-label>Inscrição municipal</mat-label>
                    <input matInput
                          maxlength="15"
                          formControlName="IM"
                          autocomplete="off">
                    <mat-error *ngIf="fiscalForm.get('IM').errors">
                      {{ fiscalForm.get('IM').errors | error | translate : fiscalForm.get('IM').errors }}
                    </mat-error>
                  </mat-form-field>
  
                  <!-- regEspTrib -->
                  <mat-form-field class="col-12 col-md-6 col-lg-4">
                    <mat-label>
                      Reg. Esp. de Trib. do Prestador
                    </mat-label>
                    <mat-select formControlName="regEspTrib"
                                title="Regime Especial de Tributação do Prestador">
                      <mat-option *ngFor="let trib of regEspTribList"
                                  [title]="trib.description"
                                  [value]="trib.code">
                        {{ trib.code }} - {{ trib.description }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- tributMunicipio -->
                  <mat-form-field class="col-12 col-md-6 col-lg-2">
                    <mat-label>Cód. trib. do município</mat-label>
                    <input matInput
                          autocomplete="off"
                          maxlength="20"
                          title="Código de tributação do município"
                          placeholder="EX: 1401"
                          formControlName="tributMunicipio">
                    <mat-error *ngIf="fiscalForm.get('tributMunicipio').errors">
                      {{ fiscalForm.get('tributMunicipio').errors | error | translate : fiscalForm.get('tributMunicipio').errors }}
                    </mat-error>
                  </mat-form-field>
  
                  <!-- cVerificaRPS -->
                  <mat-form-field class="col-12 col-md-6 col-lg-2">
                    <mat-label>Cod. Verif. RPS</mat-label>
                    <input matInput
                          title="Código de Verificação de RPS"
                          formControlName="verificaRPS">
                  </mat-form-field>
  
                  <ng-container>
                    <mat-form-field class="col-12 col-md-3 col-lg-2">
                      <mat-label>Padrão NFSe</mat-label>
                      <mat-select formControlName="patternNFSE">
                        <mat-option *ngFor="let pattern of NFSE_PATTERNS"
                                    [value]="pattern">
                          {{ pattern }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
                  
                  <mat-form-field class="col-12 col-md-6 col-lg-3">
                    <mat-label>Natureza/Exigibilidade</mat-label>
                    <mat-select formControlName="exigibilidade"
                                [compareWith]="SelectUtilities.compareByCode"
                                title="Natureza da operação/Exigibilidade de ISS">
                      <mat-option *ngFor="let natureza of NATUREZAS"
                                  [title]="natureza.description"
                                  [value]="natureza.code">
                        {{ natureza.code }} - {{ natureza.description }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
  
                  <!-- munIncidISS -->
                  <mat-form-field class="col-12 col-md-6 col-lg-3">
                    <mat-label>Município de incidência de ISS</mat-label>
                    <mat-select formControlName="munIncidISS"
                                title="Município de incidência de ISS">
                      <mat-option *ngFor="let option of ['Não informa',  'Cliente','Prestador']"
                                  [value]="option">
                        {{ option }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
  
                  <mat-form-field class="col-12 col-md-6 col-lg-2">
                    <mat-label>Valor mín. de ret. de ISS</mat-label>
                    <input matInput
                          money
                          class="txt-end"
                          [limit]="100"
                          [decimalPoints]="4"
                          formControlName="minISS">
                    <span matTextSuffix>%</span>
                  </mat-form-field>
                  
                  <mat-form-field class="col-12 col-md-6 col-lg-2">
                    <mat-label>Alíquota ISS</mat-label>
                    <input matInput
                          money
                          class="txt-end"
                          [limit]="100"
                          [decimalPoints]="4"
                          formControlName="ISS">
                    <span matTextSuffix>%</span>
                  </mat-form-field>
  
                  <!-- cnaeCode -->
                  <mat-form-field class="col-12 col-md-6 col-lg-2">
                    <mat-label>Código CNAE</mat-label>
                    <input matInput
                          formControlName="cnaeCode"
                          title="Código CNAE - Classificação Nacional de Atividades Econômicas"
                          maxlength="10"
                          integer>
                    <mat-error *ngIf="fiscalForm.get('cnaeCode').errors">
                      {{ fiscalForm.get('cnaeCode').errors | error | translate : fiscalForm.get('cnaeCode').errors }}
                    </mat-error>
                  </mat-form-field>
  
                  <!-- last NSU -->
                  <mat-form-field class="col-12 col-md-6 col-lg-2">
                    <mat-label>Último NSU</mat-label>
                    <input matInput
                          formControlName="lastNSU"
                          maxlength="15">
                    <mat-error *ngIf="fiscalForm.get('lastNSU').errors">
                      {{ fiscalForm.get('lastNSU').errors | error | translate : fiscalForm.get('lastNSU').errors }}
                    </mat-error>
                  </mat-form-field>
  
                  <!-- access key -->
                  <mat-form-field class="col-12 col-md-6 col-lg-4">
                    <mat-label>Chave de acesso</mat-label>
                    <input matInput
                          formControlName="accessKeyInvoice">
                    <mat-error *ngIf="fiscalForm.get('accessKeyInvoice').errors">
                      {{ fiscalForm.get('accessKeyInvoice').errors | error | translate : fiscalForm.get('accessKeyInvoice').errors }}
                    </mat-error>
                  </mat-form-field>
  
                  <!-- NFe number -->
                  <mat-form-field class="col-12 col-md-6 col-lg-2">
                    <mat-label>Nº NFe</mat-label>
                    <input matInput
                          integer
                          maxlength="9"
                          formControlName="numberInvoice">
                    <mat-error *ngIf="fiscalForm.get('numberInvoice').errors">
                      {{ fiscalForm.get('numberInvoice').errors | error | translate : fiscalForm.get('numberInvoice').errors }}
                    </mat-error>
                  </mat-form-field>
  
                  <!-- NFe serial -->
                  <mat-form-field class="col-12 col-md-6 col-lg-2">
                    <mat-label>Série NFe</mat-label>
                    <input matInput
                          integer
                          maxlength="3"
                          placeholder="Ex: 001"
                          formControlName="serieInvoice">
                    <mat-error *ngIf="fiscalForm.get('serieInvoice').errors">
                      {{ fiscalForm.get('serieInvoice').errors | error | translate : fiscalForm.get('serieInvoice').errors }}
                    </mat-error>
                  </mat-form-field>
  
                  <!-- NFCe number -->
                  <mat-form-field class="col-12 col-md-6 col-lg-2">
                    <mat-label>Nº NFCe</mat-label>
                    <input matInput
                          integer
                          maxlength="9"
                          formControlName="numberInvoiceNFC">
                    <mat-error *ngIf="fiscalForm.get('numberInvoiceNFC').errors">
                      {{ fiscalForm.get('numberInvoiceNFC').errors | error | translate : fiscalForm.get('numberInvoiceNFC').errors }}
                    </mat-error>
                  </mat-form-field>
  
                  <!-- NFCe serial -->
                  <mat-form-field class="col-12 col-md-6 col-lg-2">
                    <mat-label>Série NFCe</mat-label>
                    <input matInput
                          integer
                          maxlength="3"
                          placeholder="Ex: 001"
                          formControlName="serieInvoiceNFC">
                    <mat-error *ngIf="fiscalForm.get('serieInvoiceNFC').errors">
                      {{ fiscalForm.get('serieInvoiceNFC').errors | error | translate : fiscalForm.get('serieInvoiceNFC').errors }}
                    </mat-error>
                  </mat-form-field>
  
                  <!-- NFSe number (RPS) -->
                  <mat-form-field class="col-12 col-md-6 col-lg-2">
                    <mat-label>Nº da NFSe</mat-label>
                    <input matInput
                          integer
                          maxlength="15"
                          formControlName="numberInvoiceNFS">
                    <mat-error *ngIf="fiscalForm.get('numberInvoiceNFS').errors">
                      {{ fiscalForm.get('numberInvoiceNFS').errors | error | translate : fiscalForm.get('numberInvoiceNFS').errors }}
                    </mat-error>
                  </mat-form-field>
  
                  <!-- NFSe serial -->
                  <mat-form-field class="col-12 col-md-6 col-lg-2">
                    <mat-label>Série NFSe</mat-label>
                    <input matInput
                          maxlength="5"
                          uppercase
                          placeholder="Ex: NFSE1"
                          formControlName="serieInvoiceNFS">
                    <mat-error *ngIf="fiscalForm.get('serieInvoiceNFS').errors">
                      {{ fiscalForm.get('serieInvoiceNFS').errors | error | translate : fiscalForm.get('serieInvoiceNFS').errors }}
                    </mat-error>
                  </mat-form-field>
  
                  <!-- authorized CNPJs -->
                  <div class="col-12 col-lg-6">
                    <mat-form-field class="add-cpf-cnpj">
                      <mat-label>CPF/CNPJ</mat-label>
                      <input matInput
                            #cnpjCpfinput
                            type="text"
                            maxlength="18"
                            minlength="11"
                            [disabled]="fiscalForm.get('authorized').value.length >= 10">
                      <button matSuffix
                              mat-icon-button
                              (click)="[addNewCpfOrCnpj(cnpjCpfinput.value), cnpjCpfinput.value = '']">
                        <mat-icon>add</mat-icon>
                      </button>
                    </mat-form-field>
                    <div class="authorized-cpf-and-cnpj-list">
                      @for (item of fiscalForm.get('authorized').value; track $index) {
                      <div class="d-flex flex-row gap-0 justify-content-start align-items-center flex-wrap w-auto">
                        <p class="m-0 auth-documents-items"> {{ item | document }}</p>
                        <div class="d-flex flex-row justify-content-start align-items-center mr-3 me-3">
                          <button mat-icon-button
                                  (click)="toggleEdit(cnpjCpfInputEditBlock)">
                            <mat-icon>
                              edit
                            </mat-icon>
                          </button>
                          <button mat-icon-button
                                  (click)="removeAuthorizedItem($index)">
                            <mat-icon>
                              delete
                            </mat-icon>
                          </button>
                        </div>
                        <div #cnpjCpfInputEditBlock
                            class="d-none align-items-center flex-wrap w-auto">
                          <mat-form-field>
                            <mat-label>CPF/CNPJ</mat-label>
                            <input matInput
                                  #cnpjCpfInputEdit
                                  value="{{ item }}"
                                  type="text"
                                  maxlength="18"
                                  minlength="11">
                          </mat-form-field>
                          <button mat-icon-button
                                  class="mb-2"
                                  (click)="editCpfOrCnpj($index, { value: cnpjCpfInputEdit.value, htmlElement: cnpjCpfInputEditBlock })">
                            <mat-icon>check</mat-icon>
                          </button>
                          <button mat-icon-button
                                  class="mb-2"
                                  (click)="toggleEdit(cnpjCpfInputEditBlock, true)">
                            <mat-icon>close</mat-icon>
                          </button>
                        </div>
                      </div>
                      }
                    </div>
                  </div>
                }
  
                <!-- gerencial number -->
                <mat-form-field class="col-12 col-md-6 col-lg-2 h-min">
                  <mat-label>Nº gerencial</mat-label>
                  <input matInput
                          integer
                          maxlength="9"
                          formControlName="numberGerencial">
                  <mat-error *ngIf="fiscalForm.get('numberGerencial').errors">
                    {{ fiscalForm.get('numberGerencial').errors | error | translate : fiscalForm.get('numberGerencial').errors }}
                  </mat-error>
                </mat-form-field>
  
                <!-- gerencial serial -->
                <mat-form-field class="col-12 col-md-6 col-lg-2 h-min">
                  <mat-label>Série gerencial</mat-label>
                  <input matInput
                          integer
                          maxlength="3"
                          uppercase
                          placeholder="Ex: 001"
                          formControlName="serieGerencial">
                  <mat-error *ngIf="fiscalForm.get('serieGerencial').errors">
                    {{ fiscalForm.get('serieGerencial').errors | error | translate : fiscalForm.get('serieGerencial').errors }}
                  </mat-error>
                </mat-form-field>
  
              </form>
            </ng-template>
          </mat-tab>
        }

        <mat-tab label="Parâmetros">
          <ng-template matTabContent>
            <form [formGroup]="paramsForm" class="row mx-0 w-100 py-2">
              @if (!isTcarIntegrated.value) {
                <mat-checkbox color="primary"
                              [ngClass]="checkboxClasses"
                              [checked]="paramsForm.value.controlStock"
                              formControlName="controlStock"
                              title="Se marcado, o sistema vai impedir que o estoque físico de um item fique negativo">
                  Controla estoque
                </mat-checkbox>
              }

              @if (!isTcarIntegrated.value) {
                <mat-checkbox color="primary"
                              [ngClass]="checkboxClasses"
                              [checked]="paramsForm.value.discountControl == BOOLEAN_TWO.TRUE"
                              (change)="paramsForm.get('discountControl').setValue($event.checked ? BOOLEAN_TWO.TRUE : BOOLEAN_TWO.FALSE)"
                              title="Se marcado, todo desconto deverá ser liberado ou rejeitado no menu de controle de desconto">
                  Controla Desconto
                </mat-checkbox>
              }

              @if (!isTcarIntegrated.value) {
                <mat-checkbox color="primary"
                              [ngClass]="checkboxClasses"
                              [checked]="paramsForm.value.complementaryOS == BOOLEAN_TWO.TRUE"
                              (change)="paramsForm.get('complementaryOS').setValue($event.checked ? BOOLEAN_TWO.TRUE : BOOLEAN_TWO.FALSE)"
                              title="Se marcado, ao adicionar um item à uma OS, ele será adicionado como complementar">
                  Adicionar Orçamento Complementar
                </mat-checkbox>
              }

              @if (!isTcarIntegrated.value) {
                <mat-checkbox color="primary"
                              [ngClass]="checkboxClasses"
                              [checked]="paramsForm.value.osCount == BOOLEAN_TWO.TRUE"
                              (change)="paramsForm.get('osCount').setValue($event.checked ? BOOLEAN_TWO.TRUE : BOOLEAN_TWO.FALSE)"
                              title="Se marcado, as numerações de Orçamento e OS poderão ser diferentes">
                  Número de OS/Orçamento separados
                </mat-checkbox>
              }

              @if (!isTcarIntegrated.value) {
                <mat-checkbox color="primary"
                              [ngClass]="checkboxClasses"
                              [checked]="paramsForm.value.openOS == BOOLEAN_ONE.TRUE"
                              (change)="paramsForm.get('openOS').setValue($event.checked ? BOOLEAN_ONE.TRUE : BOOLEAN_ONE.FALSE)"
                              title="Se marcado, o sistema vai permitir criar OS aberta sem passar por estado de orçamento">
                  Permitir abrir OS diretamente
                </mat-checkbox>
              }

              @if (!isTcarIntegrated.value) {
                <mat-checkbox color="primary"
                              [ngClass]="checkboxClasses"
                              [checked]="paramsForm.value.costAlert == BOOLEAN_TWO.TRUE"
                              (change)="paramsForm.get('costAlert').setValue($event.checked ? BOOLEAN_TWO.TRUE : BOOLEAN_TWO.FALSE)"
                              title="Se marcado, o sistema vai alertar quando o valor de venda de um item for menor que o custo">
                  Verificar custo médio, último custo e valor líquido
                </mat-checkbox>
              }

              <mat-checkbox color="primary"
                            [ngClass]="checkboxClasses"
                            [checked]="paramsForm.value.sendCode == SEND_CODE.ALLOW"
                            (change)="paramsForm.get('sendCode').setValue($event.checked ? SEND_CODE.ALLOW : SEND_CODE.BLOCK)"
                            title="Se marcado, o PDF da OS não vai exibir o código das peças">
                Não enviar código de peças no PDF
              </mat-checkbox>

              @if (!isTcarIntegrated.value) {
                <mat-checkbox color="primary"
                              [ngClass]="checkboxClasses"
                              [checked]="paramsForm.value.controlFinc"
                              formControlName="controlFinc"
                              title="Se marcado, a data de baixa não poderá ser anterior à data de criação do título">
                  Controla financeiro
                </mat-checkbox>
              }

              @if (!isTcarIntegrated.value) {
                <div class="col-6 col-md-6 ps-4 pl-4">
                  <mat-label>Tipo de Veículo:</mat-label>
                  <mat-radio-group formControlName="typeVehicle" class="col-12">
                    <mat-radio-button [value]="TYPE_VEHICLE.MOTORCYCLE">Moto</mat-radio-button>
                    <mat-radio-button [value]="TYPE_VEHICLE.CAR">Carro</mat-radio-button>
                    <mat-radio-button [value]="TYPE_VEHICLE.TRUCK">Caminhão</mat-radio-button>
                    <mat-radio-button [value]="TYPE_VEHICLE.BUS">Ônibus</mat-radio-button>
                    @if (isAgro) {
                      <mat-radio-button [value]="TYPE_VEHICLE.AGRO">Máquinas Agricolas</mat-radio-button>
                    }
                  </mat-radio-group>
                </div>      
              }
            </form>
          </ng-template>
        </mat-tab>
      </mat-tab-group>

    </div>
    <div class="d-flex justify-content-end align-items-center gap-1">
      <button aria-label="cancel"
              mat-raised-button
              color="accent"
              type="button"
              (click)="goBack()">
        {{ 'ACTION.SHARED.CANCEL' | translate }}
      </button>
      <button aria-label="save"
              mat-raised-button
              color="primary"
              type="button"
              (singleClick)="onSubmit()"
              [disabled]="formArray.invalid || formArray.pristine">
        {{ 'ACTION.SHARED.SAVE_CHANGES' | translate }}
      </button>
    </div>
</div>
</div>
