import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectUtilities } from 'src/app/class/select-utilities';
import { UnsubscribeAll } from 'src/app/class/unsubscribe-all';
import { Utilities } from 'src/app/class/utilities';
import { ConfirmationDialogComponent } from 'src/app/component/dialog/confirmation-dialog/confirmation-dialog.component';
import { PhotoEditComponent } from 'src/app/component/dialog/photo-edit/photo-edit.component';
import { Company, COMPANY_STATE, SEND_CODE, TYPE_VEHICLE } from 'src/app/interface/company';
import { State } from 'src/app/interface/state';
import { AddressService } from 'src/app/service/address.service';
import { CompanyService } from 'src/app/service/company.service';
import { LayoutService } from 'src/app/service/layout.service';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { BOOLEAN_ONE } from 'src/app/shared/common-enums/boolean-one';
import { BOOLEAN_TWO } from 'src/app/shared/common-enums/boolean-two';
import { companyBrands } from 'src/app/shared/lists/company-brands';
import { COMPANY_TAB, companyTabs } from 'src/app/shared/lists/company-tabs';
import { NATUREZAS_DE_OPERACAO } from 'src/app/shared/lists/naturezas-de-operacao';
import { NFSE_PATTERN, NFSE_PATTERNS } from 'src/app/shared/lists/nfse-patterns';
import { REGIM_ESPEC_TRIBUT_PREST } from 'src/app/shared/lists/reg_esp_trib_pres';
import { DocumentPipe } from 'src/app/shared/pipes/document.pipe';
import { ObjectId } from 'src/app/shared/type-aliases/object-id';
import { environment } from 'src/environments/environment';

const SERIE_RGX = /^[1-9]{1}[0-9]{0,2}$|^0{1}$/;
const NFSE_SERIE_RGX = /^\w{1,5}$/;

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrl: './company-detail.component.scss'
})
export class CompanyDetailComponent extends UnsubscribeAll implements OnInit, OnDestroy {
  SelectUtilities = SelectUtilities;
  
  identifycationForm = new FormGroup({
    id: new FormControl<ObjectId>(''),
    socialName: new FormControl('', [Validators.required, Validators.minLength(2)]),
    fancyName: new FormControl('', Validators.required),
    cnpj: new FormControl('', [Validators.required, Utilities.validateCNPJ()]),
    cnpjMatrix: new FormControl('', Utilities.validateCNPJ()),
    companyLogo: new FormControl(''),
    associateLogo: new FormControl(''),
    companyLogoInput: new FormControl(''),
    associationLogoInput: new FormControl('')
  },  {
    validators: [
      Utilities.distinctControls('cnpj', 'cnpjMatrix') // both CNPJ should be distinct
    ]
  })
  
  addressForm = new FormGroup({
    cep: new FormControl('', [Validators.minLength(8), Validators.required]),
    street: new FormControl('', [Validators.minLength(2), Validators.maxLength(60), Validators.required]),
    number: new FormControl('', Validators.required),
    complement: new FormControl(''),
    neighborhood: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(60)]),
    city: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required)
  });

  paramsForm = new FormGroup({
    controlStock: new FormControl<Company['controlStock']>(false),
    discountControl: new FormControl<Company['discountControl']>(BOOLEAN_TWO.FALSE),
    complementaryOS: new FormControl<Company['complementaryOS']>(BOOLEAN_TWO.TRUE),
    osCount: new FormControl<Company['osCount']>(BOOLEAN_TWO.FALSE),
    openOS: new FormControl<Company['openOS']>(BOOLEAN_ONE.TRUE),
    costAlert: new FormControl<Company['costAlert']>(BOOLEAN_TWO.FALSE),
    sendCode: new FormControl<Company['sendCode']>(SEND_CODE.ALLOW),
    controlFinc: new FormControl<Company['controlFinc']>(false),
    typeVehicle: new FormControl<Company["typeVehicle"]>(TYPE_VEHICLE.CAR)
  });
  
  fiscalForm = new FormGroup({
    deduNFSE: new FormControl<Company['deduNFSE']>(false),
    deduNFE: new FormControl<Company['deduNFE']>(false),
    deduISS: new FormControl<Company['deduISS']>(false),
    crt: new FormControl<Company['crt']>(undefined, [Validators.required, Validators.min(1), Validators.max(4)]),
    stateRegistration: new FormControl('', Validators.required),
    IM: new FormControl('', [Validators.required]),
    tributMunicipio: new FormControl(''),
    regEspTrib: new FormControl(''),
    verificaRPS: new FormControl(''),
    exigibilidade: new FormControl<Company['exigibilidade']>(undefined),
    munIncidISS: new FormControl<Company['munIncidISS']>('Não informa'),
    ISS: new FormControl<Company['ISS']>(0),
    numberInvoice: new FormControl('', Validators.maxLength(9)),
    numberInvoiceNFC: new FormControl('', Validators.maxLength(9)),
    numberInvoiceNFS: new FormControl('', Validators.maxLength(15)),
    numberGerencial: new FormControl('', Validators.maxLength(9)),
    minISS: new FormControl<Company["minISS"]>(0),
    serieInvoice: new FormControl<Company['serieInvoice']>(undefined, [
      Validators.minLength(1),
      Validators.maxLength(3),
      Validators.pattern(SERIE_RGX),
      // Utilities.differentValues(['serieInvoiceNFC', 'serieInvoiceNFS', 'serieGerencial'])
    ]),
    serieInvoiceNFC: new FormControl<Company['serieInvoiceNFC']>(undefined, [
      Validators.minLength(1),
      Validators.maxLength(3),
      Validators.pattern(SERIE_RGX),
      // Utilities.differentValues(['serieInvoice', 'serieInvoiceNFS', 'serieGerencial'])
    ]),
    serieInvoiceNFS: new FormControl<Company['serieInvoiceNFS']>(undefined, [
      Validators.minLength(1),
      Validators.maxLength(5),
      Validators.pattern(NFSE_SERIE_RGX),
      // Utilities.differentValues(['serieInvoice', 'serieInvoiceNFC', 'serieGerencial'])
    ]),
    serieGerencial: new FormControl<Company['serieGerencial']>(undefined, [
      Validators.minLength(1),
      Validators.maxLength(3),
      Validators.pattern(SERIE_RGX),
      // Utilities.differentValues(['serieInvoice', 'serieInvoiceNFC', 'serieInvoiceNFS'])
    ]),
    cnaeCode: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(10)]),
    lastNSU: new FormControl(''),
    accessKeyInvoice: new FormControl(''),
    migrateIntegrated: new FormControl<Company['migrateIntegrated']>(true),
    patternNFSE: new FormControl<NFSE_PATTERN>(undefined),
    authorized: new FormControl<Company['authorized']>([])
  });
  
  contactForm = new FormGroup({
    phone: new FormControl(''),
    phone2: new FormControl(''),
    email: new FormControl('', Validators.email),
    site: new FormControl(''),
    tokenWhats: new FormControl('')
  })
  
  formArray = new FormArray([
    this.contactForm,
    this.fiscalForm,
    this.identifycationForm,
    this.addressForm,
    this.paramsForm,
  ])

  TYPE_VEHICLE = TYPE_VEHICLE;
  isAgro = environment.agro;

  states: State[] = [];
  openDialog: MatDialogRef<ConfirmationDialogComponent> | MatDialogRef<PhotoEditComponent>;
  companyLogoBlob: Blob;
  associationLogoBlob: Blob;
  companyBrands = companyBrands;
  companyTabs = companyTabs;
  COMPANY_TAB = COMPANY_TAB;
  BOOLEAN_ONE = BOOLEAN_ONE;
  BOOLEAN_TWO = BOOLEAN_TWO;
  SEND_CODE = SEND_CODE;
  regEspTribList = REGIM_ESPEC_TRIBUT_PREST;
  NFSE_PATTERNS = NFSE_PATTERNS.sort((pt1, pt2) => pt1.localeCompare(pt2));
  NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => {
    return [1, 2, 3, 4, 10, 11].includes(nat.code) // default values
  });
  isMakena = environment.makena;
  companyData: Company;
  authorizatedCpfAndCpnjInput = new FormControl("");
  isTcarIntegrated = new FormControl<boolean>(false);
  isMobile: boolean;

  checkboxClasses = ['col-12', 'col-md-6', 'col-lg-4', 'col-lg-3', 'col-xl-2', 'col-xxl-1'];

  constructor(
    private _companyService: CompanyService,
    private _addressService: AddressService,
    private _layout: LayoutService,
    private _snackbar: SnackbarService,
    private _dialog: MatDialog,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    // clear city on change state
    this.subs.push(this.addressForm.get('state').valueChanges
      .subscribe((value: string) => {
        this.addressForm.get('city').setValue('');
      }));

    // find address on input cep
    this.subs.push(this.addressForm.get('cep').valueChanges
      .subscribe(async (value: string) => {
        const cep = Utilities.removeMask(value);
        if (cep.length === 8) {
          try {
            const address = await this._addressService.searchCep(cep);
            this.addressForm.get('number').setValue('');
            this.addressForm.get('complement').setValue('');
            this.addressForm.get('street').setValue(address.street);
            this.addressForm.get('neighborhood').setValue(address.neighborhood);
            this.addressForm.get('state').setValue(address.state);
            this.addressForm.get('city').setValue(address.city);
          } catch (error) {
            console.error(error);
          }
        }
      }));

    if (!environment.makena) {
      // Update required fields for company integrated with Migrate
      this.subs.push(this.fiscalForm.get('migrateIntegrated').valueChanges
        .subscribe((isIntegrated: boolean) => {
          this._handleMigrateIntegratedChanges(isIntegrated);
          this._handleTcarIntegratioinChange(!isIntegrated);
        }));

      // the <tributMunicipio> tag is required for some NFSE patterns
      this.subs.push(this.fiscalForm.get('patternNFSE').valueChanges
        .subscribe((value: Company['patternNFSE']) => {

          switch (value) {
            case "Governo Digital":
              this.regEspTribList = REGIM_ESPEC_TRIBUT_PREST;
              this.NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => [3, 4, 5, 6, 7, 8, 9].includes(nat.code));
              break;
            case "Embras Siap.Net":
            case "Síntese Tecnologia":
            case "Futurize":
              this.regEspTribList = REGIM_ESPEC_TRIBUT_PREST.slice(1, 7);
              this.NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => [7, 8, 3, 9, 4, 5, 6].includes(nat.code));
              break;
            case "SIGCORP ABRASF":
            case "SMARAPDSil WS2":
            case "HM2 Soluções":
            case "WEB ISS 2.0":
            case "TIPLAN 2.0":
              this.regEspTribList = REGIM_ESPEC_TRIBUT_PREST;
              this.NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => [7, 8, 3, 9, 4, 5, 6].includes(nat.code));
              break;
            case "IPM":
            case "IPM (Araranguá)":
            case "IPM (Brusque)":
              this.regEspTribList = REGIM_ESPEC_TRIBUT_PREST;
              this.NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => [1, 2, 3, 4, 10, 11].includes(nat.code));
              break;

            case "Assessor Público":
              this.regEspTribList = REGIM_ESPEC_TRIBUT_PREST;
              this.NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => [12, 14, 16, 17, 1].includes(nat.code));
              break;
            case "Assessor Público (Lins-SP)":
              this.regEspTribList = REGIM_ESPEC_TRIBUT_PREST;
              this.NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => [1, 3, 4, 8, 12, 14, 16, 17, 42].includes(nat.code));
              break;
            case "Assessor Público (Votorantim-SP)":
              this.regEspTribList = REGIM_ESPEC_TRIBUT_PREST;
              this.NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => [3, 4, 7, 10, 12, 14, 17].includes(nat.code));
              break;
            case "NFPSe WS":
              this.NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => [1, 3, 4, 8, 10, 11, 12, 15, 16, 27].includes(nat.code))
              break;
            default:
              this.regEspTribList = REGIM_ESPEC_TRIBUT_PREST;
              this.NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => [1, 2, 3, 4, 10].includes(nat.code));
              break;
          }

          if (value === "Futurize") {
            // CNAE is optional for some majors with pattern Futurize
            this.fiscalForm.get('CNAE').removeValidators(Validators.required);
            this.fiscalForm.get('CNAE').updateValueAndValidity();
          } else {
            this.fiscalForm.get('CNAE').addValidators(Validators.required);
            this.fiscalForm.get('CNAE').updateValueAndValidity();
          }

          if (value === 'IPM (Araranguá)') {
            this.fiscalForm.get('tributMunicipio').setValidators(null);
            this.fiscalForm.get('tributMunicipio').updateValueAndValidity();
          } else {
            this.fiscalForm.get('tributMunicipio').setValidators([Validators.required]);
            this.fiscalForm.get('tributMunicipio').updateValueAndValidity();
          }
        }));
    }

    this.subs.push(this._layout.isHandset$.subscribe(isMobile => this.isMobile = isMobile));
    
    this.getData();
  }

  private _handleMigrateIntegratedChanges(isMigrateIntegrated: boolean) {
    if (isMigrateIntegrated) {
      this.fiscalForm.controls.lastNSU.setValidators([Validators.required]);
      this.fiscalForm.controls.accessKeyInvoice.setValidators([Validators.required]);
      this.fiscalForm.controls.deduNFE.enable();
      this.fiscalForm.controls.deduISS.enable();
      this.fiscalForm.controls.deduNFSE.enable();
    } else {
      this.fiscalForm.controls.lastNSU.setValidators(null);
      this.fiscalForm.controls.accessKeyInvoice.setValidators(null);
      this.fiscalForm.controls.deduNFE.disable();
      this.fiscalForm.controls.deduISS.disable();
      this.fiscalForm.controls.deduNFSE.disable();
    }

    this.fiscalForm.controls.lastNSU.updateValueAndValidity();
    this.fiscalForm.controls.accessKeyInvoice.updateValueAndValidity();
  }
  
  private _handleTcarIntegratioinChange(isTcarIntegrated: boolean) {
    if (isTcarIntegrated) {
      this.fiscalForm.controls.crt.removeValidators(Validators.required);
      this.fiscalForm.controls.stateRegistration.removeValidators(Validators.required);
      this.fiscalForm.controls.IM.removeValidators(Validators.required);
      this.fiscalForm.controls.cnaeCode.removeValidators(Validators.required);
    } else {
      this.fiscalForm.controls.crt.addValidators(Validators.required);
      this.fiscalForm.controls.stateRegistration.addValidators(Validators.required);
      this.fiscalForm.controls.IM.addValidators(Validators.required);
      this.fiscalForm.controls.cnaeCode.addValidators(Validators.required);
    }

    this.fiscalForm.controls.crt.updateValueAndValidity();
    this.fiscalForm.controls.stateRegistration.updateValueAndValidity();
    this.fiscalForm.controls.IM.updateValueAndValidity();
    this.fiscalForm.controls.cnaeCode.updateValueAndValidity();
  }
  
  ngOnDestroy() {
    this.companyLogoBlob = null;
    this.associationLogoBlob = null;
    this.unsubscribeAll();
  }

  private async getData() {
    this._layout.loader = true;
    try {
      [this.states] = await Promise.all([
        this._addressService.getStates()
      ]);

      const companyId = this._activatedRoute.snapshot.params['company'];

      if (companyId) {
        const company = await this._companyService.get(companyId, true);
        this.companyData = company;

        this.identifycationForm.patchValue(company, { emitEvent: false });
        this.addressForm.patchValue(company.address, { emitEvent: false });
        this.contactForm.patchValue(company, { emitEvent: false });
        this.fiscalForm.patchValue(company, { emitEvent: false });
        this.paramsForm.patchValue(company, { emitEvent: false });
        this.isTcarIntegrated.setValue(company?.currentState == COMPANY_STATE.integrated);
      } else {
        this.subs.push(this.isTcarIntegrated.valueChanges.subscribe(isIntegrated => {
          this._handleTcarIntegratioinChange(isIntegrated);
          this._handleMigrateIntegratedChanges(!isIntegrated);
        }))
      }

      this._handleMigrateIntegratedChanges(this.fiscalForm.get('migrateIntegrated')?.value);
      this._handleTcarIntegratioinChange(this.isTcarIntegrated.value);
    } catch (error) {
      console.error(error);
    } finally {
      this._layout.loader = false;
    }
  }

  public onInputCNPJ() {
    const cnpjInput = this.identifycationForm.get('cnpj');
    const validStr = cnpjInput.value.replace(/\D/g, '');
    cnpjInput.setValue(validStr);
  }
  
  async onInputCep() {
    const cep = Utilities.removeMask(this.addressForm.value.cep);

    if (cep.length === 8) {
      try {
        const address = await this._addressService.searchCep(cep);
        this.addressForm.get('number').setValue('');
        this.addressForm.get('complement').setValue('');
        this.addressForm.get('street').setValue(address.street);
        this.addressForm.get('neighborhood').setValue(address.neighborhood);
        this.addressForm.get('state').setValue(address.state);
        this.addressForm.get('city').setValue(address.city);
      } catch (error) {
        console.error(error);
      }
    }
  }

  onChangeState() {
    this.addressForm.get('city').setValue('');
  }

  onClick(element: HTMLInputElement) {
    element.click();
  }

  async onChangeCompanyLogo(event: Event) {
    const files = (event.target as any).files as FileList;
    if (!files.length) {
      return;
    }
    const file = files[0];
    if (!Utilities.isImage(file)) {
      return;
    }
    this.openDialog = this._dialog
      .open(PhotoEditComponent, { disableClose: true, data: { file: file, ratio: 3 } });
    const canvas: HTMLCanvasElement = await this.openDialog.afterClosed().toPromise();
    this.openDialog = null;
    if (!canvas) {
      return;
    }
    this.companyLogoBlob = await Utilities.toBlob(canvas);
    const oldCanvas = document.getElementById('company-logo-canvas') as HTMLCanvasElement;
    canvas.id = oldCanvas.id;
    canvas.classList.add('photo');
    oldCanvas.replaceWith(canvas);
  }

  public async onChangeAssociationLogo(event: Event) {
    const files = (event.target as any).files as FileList;
    if (!files.length) {
      return;
    }
    const file = files[0];
    if (!Utilities.isImage(file)) {
      return;
    }
    this.openDialog = this._dialog
      .open(PhotoEditComponent, { disableClose: true, data: { file: file, ratio: 3 } });
    const canvas: HTMLCanvasElement = await this.openDialog.afterClosed().toPromise();
    this.openDialog = null;
    if (!canvas) {
      return;
    }
    this.associationLogoBlob = await Utilities.toBlob(canvas);
    const oldCanvas = document.getElementById('association-logo-canvas') as HTMLCanvasElement;
    canvas.id = oldCanvas.id;
    canvas.onclick = oldCanvas.onclick;
    canvas.classList.add('photo');
    oldCanvas.replaceWith(canvas);
  }

  public async deleteCompanyLogo() {
    this.openDialog = this._dialog
      .open(ConfirmationDialogComponent, { disableClose: true, data: { text: 'DELETE_IMAGE' } });
    const result: boolean = await this.openDialog.afterClosed().toPromise();
    this.openDialog = null;
    if (result) {
      document.getElementById('company-logo-canvas').classList.add('hidden');
      this.identifycationForm.get('companyLogo').setValue('');
      this.companyLogoBlob = null;
    }
  }

  public async deleteAssociationLogo() {
    this.openDialog = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: { text: 'DELETE_IMAGE' }
    });
    const result: boolean = await this.openDialog.afterClosed().toPromise();
    this.openDialog = null;
    if (result) {
      document.getElementById('association-logo-canvas').classList.add('hidden');
      this.identifycationForm.get('associateLogo').setValue('');
      this.associationLogoBlob = null;
    }
  }

  public async onSubmit() {
    let company: Company;
    const identifycation = this.identifycationForm.getRawValue();
    const address = this.addressForm.getRawValue();
    const params = !this.isTcarIntegrated.value ? this.paramsForm.getRawValue() : { 
      sendCode: this.paramsForm.controls?.sendCode?.value
    };
    const fiscal = !this.isTcarIntegrated.value ? this.fiscalForm.getRawValue() : { } as any;
    const contact = this.contactForm.getRawValue();
    company = { 
      ...identifycation,
      address,
      ...params,
      ...fiscal,
      ...contact,
    }
    
    console.log({company, fiscal})
    const param = { cnpj: identifycation.cnpj };
    const isUpdate = Boolean(company?.id);

    company.cnpj = this.addCnpjMask(company.cnpj);

    try {
      this._layout.loader = true;
      company.companyLogoBlob = this.companyLogoBlob;
      company.associationLogoBlob = this.associationLogoBlob;
      if (isUpdate) {
        await this._companyService.update(company)
      } else {
        await this._companyService.add(company);
      }
      this.goBack()
      this._snackbar.success(isUpdate ? 'COMPANY.UPDATED' : 'COMPANY.ADDED', param);
    } catch (error) {
      this._snackbar.error(isUpdate ? 'COMPANY.NOT_UPDATED' : 'COMPANY.NOT_ADDED', param, error);
    } finally {
      this._layout.loader = false;
    }
  }

  addCnpjMask(cnpj: string) {
    return new DocumentPipe().transform(cnpj);
  }

  goBack() {
    this._router.navigate(["company"])
  }

  addNewCpfOrCnpj(value: string) {
    value = value.replaceAll(/\D/g, "");

    if (this.validateCpfCnpjValue(value, 11)) {
      this.addAuthorizedItem(value);
    } else if (this.validateCpfCnpjValue(value, 14)) {
      this.addAuthorizedItem(value);
    } else {
      this._snackbar.error("COMPANY.INVALID_DOCUMENT_ADD");
    }
  }

  addAuthorizedItem(value: string) {
    const authorizedPreviousValue = this.fiscalForm.get('authorized').value;
    if (authorizedPreviousValue.length === 10) {
      this._snackbar.error("COMPANY.MAX_REGISTERS_SIZE")
      return;
    }
    this.fiscalForm.get('authorized').setValue([...authorizedPreviousValue, value]);
    this.fiscalForm.markAsDirty();
  }

  removeAuthorizedItem(index: number) {
    const authorizedPreviousValue = this.fiscalForm.get('authorized').value;

    (authorizedPreviousValue as []).splice(index, 1);

    this.fiscalForm.get('authorized').setValue(authorizedPreviousValue)
    this.fiscalForm.markAsDirty();
  }

  toggleEdit(htmlElement: HTMLDivElement, cancel = false) {
    if (cancel) {
      htmlElement.classList.add("d-none");
      htmlElement.classList.remove("d-flex");
      return;
    }

    if (htmlElement.classList.contains("d-none")) {
      htmlElement.classList.remove("d-none");
      htmlElement.classList.add("d-flex");
    } else if (htmlElement.classList.contains("d-flex")) {
      htmlElement.classList.add("d-none");
      htmlElement.classList.remove("d-flex");
    }
  }

  editCpfOrCnpj(index: number, { value, htmlElement }: { value: string, htmlElement: HTMLDivElement }) {
    value = value.replaceAll(/\D/g, "");
    const authorizedPreviousValue = this.fiscalForm.get('authorized').value;

    if (this.validateCpfCnpjValue(value, 11)) {
      authorizedPreviousValue[index] = value;
      htmlElement.classList.add("d-none");
      htmlElement.classList.remove("d-flex");
    } else if (this.validateCpfCnpjValue(value, 14)) {
      authorizedPreviousValue[index] = value;
      htmlElement.classList.add("d-none");
      htmlElement.classList.remove("d-flex");
    } else {
      this._snackbar.error("COMPANY.INVALID_DOCUMENT_UPDATE");
    }
  }

  validateCpfCnpjValue(value: string, length: number): boolean {
    return value.length === length && !value.match(/[a-zA-Z!@#$%&*()\/\+]/g)
  }

  generateIntegrationKey() {
    this._companyService.generateIntegrationTokenFor({ cnpj: this.identifycationForm.get('cnpj').value })
      .subscribe({
        next: (key) => this._dialog.open(ConfirmationDialogComponent, {
          data: {
            text: "CONFIRMATION.KEY_GENERATED",
            param: key
          }
        }),
        error: (error) => this._snackbar.error("COMPANY.ERROR_GENERATE_KEY", null, error)
      });
  }
}
