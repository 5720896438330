import { environment } from "src/environments/environment";
import { Company } from "../../../../interface/company";
import { SideMenu } from "../../../../interface/side-menu";
import { User } from "../../../../interface/user";
import { roles } from "../../../../shared/lists/roles";


export const MEKANIC_MENU: (company: Company, user: User) => SideMenu[] = (company, user) => {

  const APP_NAME = environment.envName;
  let vehicleIcon = "";
  
  if(APP_NAME == "MekanicAGRO"){
    vehicleIcon = "agriculture"
  } else {
    vehicleIcon = "directions_car";
  }

  const menus: SideMenu[] = [
    // company
    {
      component: 'company',
      href: ['/company'],
      icon: 'domain',
    },

    // quick access of favorite menus
    {
      component: 'favorites',
      icon: 'star',
      childs: [
        {
          component: 'configure',
          href: ['company', company.id, 'quick-access']
        }
      ]
    },

    // home ()
    {
      component: 'home',
      profileModule: "YARD",
      href: ['company', company.id, 'home'],
      blockedRoles: [],
      icon: 'home'
    },

    // budgets
    {
      component: 'budgets',
      profileModule: "OS",
      blockedRoles: [roles.mechanic.id],
      icon: 'assignment',
      shadows: ['budget-create'],
      childs: [
        {
          component: 'budget',
          shadows: ['budget_detail', 'ro_create'],
          profileRule: "OS.list",
          href: ['company', company.id, 'os', 'budget']
        },
        {
          component: 'rejected',
          shadows: ['rejected_detail'],
          profileRule: "OS.list",
          href: ['company', company.id, 'os', 'rejected']
        }
      ]
    },

    // OSs
    {
      component: 'ro',
      profileModule: "OS",
      blockedRoles: [roles.mechanic.id],
      icon: 'assignment_turned_in',
      shadows: ['ro-create'],
      childs: [
        {
          component: 'opened',
          profileRule: "OS.list",
          shadows: ['opened_detail'],
          href: ['company', company.id, 'os', 'opened']
        },
        {
          component: 'closed',
          profileRule: "OS.list",
          shadows: ['closed_detail'],
          href: ['company', company.id, 'os', 'closed']
        },
        {
          component: 'ended',
          profileRule: "OS.list",
          shadows: ['ended_detail'],
          href: ['company', company.id, 'os', 'ended']
        }
      ]
    },

    // calendar
    {
      component: 'calendar',
      profileModule: "CALENDAR",
      icon: 'today',
      blockedRoles: [roles.mechanic.id],
      href: ['company', company.id, 'calendar']
    },

    // CRM
    {
      component: 'CRM',
      profileModule: "CRM",
      blockedRoles: [roles.mechanic.id],
      icon: 'reduce_capacity',
      childs: [
        {
          component: 'lead-list',
          profileRule: "CRM.leads.read",
          href: ['company', company.id, 'crm', 'lead-list']
        },
        {
          component: 'contact-list',
          profileRule: "CRM.contacts.read",
          href: ['company', company.id, 'crm', 'contact-list']
        },
        {
          component: 'crm-events',
          profileRule: "CRM.events.read",
          href: ['company', company.id, 'crm', 'events']
        },
        {
          component: 'crm-dashboard',
          profileRule: "CRM.dashboard",
          href: ['company', company.id, 'crm', 'dashboard']
        }
      ]
    },

    // clients
    {
      component: 'clients',
      profileModule: "CLIENT",
      blockedRoles: [roles.mechanic.id],
      icon: 'assignment_ind',
      href: ['company', company.id, 'clients'],
      shadows: ['client-detail']
    },

    // vehicles
    {
      component: environment.agro ? 'machines' : 'vehicles',
      profileModule: "VEHICLE",
      blockedRoles: [roles.mechanic.id],
      icon: vehicleIcon,
      href: ['company', company.id, 'vehicles'],
      shadows: ['vehicle-detail', 'search-vehicle']
    },

    // parts
    {
      component: 'parts',
      profileModule: "PART",
      blockedRoles: [roles.mechanic.id],
      icon: 'settings',
      href: ['company', company.id, 'parts'],
      shadows: ['part-detail'],
      childs: [{
        component: 'maintenance',
        profileRule: "PART.update",
        href: ['company', company.id, 'parts', 'maintenance']
      }]
    },

    // labors
    {
      component: 'labors',
      profileModule: "LABOR",
      blockedRoles: [roles.mechanic.id],
      svgIcon: 'labor',
      href: ['company', company.id, 'labors'],
      shadows: ['labor-detail']
    },

    // cash (financial)
    {
      component: 'cash',
      profileModule: "FINANCIAL",
      blockedRoles: [roles.mechanic.id, roles.consultant.id],
      icon: 'local_atm',
      childs: [
        {
          component: 'cash-title',
          profileRule: "FINANCIAL.titles.read",
          href: ['company', company.id, 'financial', 'cash-title'],
          shadows: ['cash-title-detail']
        },
        {
          component: 'receipt',
          profileRule: "FINANCIAL.cashier.read",
          href: ['company', company.id, 'financial', 'receipt'],
          shadows: ['closed-cashier']
        },
        {
          component: 'banks',
          profileRule: "FINANCIAL.banks.read",
          href: ['company', company.id, 'financial', 'banks']
        },
        {
          component: 'conciliation',
          profileRule: "FINANCIAL.conciliation.read",
          href: ['company', company.id, 'financial', 'conciliation'],
          shadows: ['conciliation-detail']
        },
        {
          component: 'detached',
          profileRule: "FINANCIAL.detached.read",
          href: ['company', company.id, 'financial', 'detached']
        },
        {
          component: 'cash-flow-control',
          profileRule: "FINANCIAL.cash-flow-control.read",
          href: ['company', company.id, 'financial', 'cash-flow-control']
        }
      ]
    },

    // suppliers
    {
      component: 'suppliers',
      profileModule: "SUPPLIER",
      blockedRoles: [roles.mechanic.id],
      icon: 'local_shipping',
      href: ['company', company.id, 'suppliers'],
      shadows: ['supplier-detail']
    },

    // orders
    {
      component: 'orders',
      profileModule: "ORDER",
      blockedRoles: [roles.mechanic.id],
      icon: 'ballot',
      href: ['company', company.id, 'order'],
      shadows: ['order-detail']
    },

    // users
    {
      component: 'users',
      profileModule: "USER",
      blockedRoles: [roles.mechanic.id, roles.consultant.id],
      icon: 'people',
      childs: [
        {
          profileRule: "USER.REGISTERED_USERS.read",
          component: 'user-list',
          href: ['company', company.id, 'users'],
          shadows: ['user-detail'],
        },

        // access-profiles
        {
          inDevelopment: true,
          profileRule: "USER.ACCESS_CONTROL.read",
          component: 'access-profile',
          href: ['company', company.id, 'access-profile'],
          shadows: ['access-profile-edit']
        }
      ]
    },


    // invoices
    {
      component: 'invoices',
      profileModule: "INVOICE",
      blockedRoles: [roles.mechanic.id],
      icon: 'receipt',
      href: ['company', company.id, 'invoices'],
      shadows: ['invoice-detail'],
      childs: [
        {
          component: 'xml',
          profileRule: "INVOICE.importXML",
          href: ['company', company.id, 'invoices', 'xml']
        }
      ]
    },

    // operations
    {
      component: 'operations',
      profileModule: "OPERATION",
      blockedRoles: [roles.mechanic.id, roles.consultant.id],
      icon: 'category',
      href: ['company', company.id, 'operations'],
      shadows: ['operation-detail']
    },

    // stock
    {
      component: 'stock',
      profileModule: "STOCK",
      blockedRoles: [roles.mechanic.id],
      icon: 'widgets',
      href: ['company', company.id, 'stock']
    },

    // dashboard
    {
      component: 'indicators',
      profileModule: "BUSINESS_MANAGEMENT",
      blockedRoles: [roles.mechanic.id, roles.consultant.id],
      icon: 'pie_chart',
      childs: [{
        component: 'info',
        href: ['company', company.id, 'dashboard'],
        profileRule: "BUSINESS_MANAGEMENT.read",
        shadows: [
          'info',
          'revenue-indicators-sales',
          'ranking-sellers-average-ticket',
          'ranking-sellers-clients-served',
          'ranking-sellers-sales',
          'revenue-indicators-average-ticket',
          'revenue-indicators-groups',
        ]
      }]
    },

    // Discount Control
    {
      component: 'discount-control',
      blockedRoles: [roles.mechanic.id, roles.consultant.id],
      profileModule: "DISCOUNT_CONTROL",
      icon: 'notifications_active',
      href: ['company', company.id, 'discount-control']
    },

    // reports
    {
      component: 'reports',
      profileModule: "REPORTS",
      blockedRoles: [roles.mechanic.id, roles.consultant.id],
      icon: 'description',
      childs: [
        {
          component: 'commissions',
          profileRule: 'REPORTS.commissions',
          href: ['company', company.id, 'reports', 'commissions']
        },
        {
          component: 'dashboard',
          profileRule: 'REPORTS.dashboard',
          href: ['company', company.id, 'reports', 'dashboard']
        },
        {
          component: 'sales',
          profileRule: 'REPORTS.sales',
          href: ['company', company.id, 'reports', 'sales']
        },
        {
          component: 'user-sales',
          profileRule: 'REPORTS.userSales',
          href: ['company', company.id, 'reports', 'user-sales']
        },
        {
          component: 'profits',
          profileRule: 'REPORTS.profits',
          href: ['company', company.id, 'reports', 'profits']
        },
        {
          component: 'profits-by-os',
          profileRule: 'REPORTS.profitsByOs',
          href: ['company', company.id, 'reports', 'profits-by-os']
        },
        {
          component: 'mechanic-time',
          profileRule: 'REPORTS.mechanicTime',
          href: ['company', company.id, 'reports', 'mechanic-time']
        },
        {
          component: 'dre',
          profileRule: 'REPORTS.dre',
          href: ['company', company.id, 'reports', 'dre']
        },
        {
          component: 'os-vehicle-history',
          profileRule: 'REPORTS.osVehicleHistory',
          href: ['company', company.id, 'reports', 'os-vehicle-history']
        },
        {
          component: 'vehicle-history',
          profileRule: 'REPORTS.vehicleHistory',
          href: ['company', company.id, 'reports', 'vehicle-history']
        },
        {
          component: 'os-history',
          profileRule: 'REPORTS.osHistory',
          href: ['company', company.id, 'reports', 'os-history']
        },
        {
          component: 'infocar-queries',
          profileRule: "REPORTS.infocarQueries",
          href: ['company', company.id, 'reports', 'infocar-queries']
        }
      ]
    },

    // records
    {
      component: 'records',
      profileModule: "RECORDS",
      blockedRoles: [roles.mechanic.id, roles.consultant.id],
      icon: 'note_add',
      childs: [
        {
          component: 'application-list',
          profileRule: "RECORDS.applications.read",
          href: ['company', company.id, 'records', 'applications']
        },
        {
          component: 'bank-list',
          profileRule: "RECORDS.banks.read",
          href: ['company', company.id, 'records', 'banks']
        },
        {
          component: 'carte-list',
          profileRule: "RECORDS.cartes.read",
          href: ['company', company.id, 'records', 'carte-list']
        },
        // {
        //   component: 'additional-item',
        //   profileRule: "RECORDS.cartes.update",
        //   href: ['company', company.id, 'records', 'additional-item']
        // },
        // {
        //   component: 'parcels',
        //   profileRule: "RECORDS.cartes.update",
        //   href: ['company', company.id, 'records', 'parcels']
        // },
        {
          component: 'checklist',
          profileRule: "RECORDS.mkgChecklist.read",
          href: ['company', company.id, 'records', 'checklist']
        },
        {
          component: 'checklist-models',
          profileRule: "RECORDS.checklistByModel.read",
          href: ['company', company.id, 'records', 'checklist-model']
        },
        {
          component: 'payment-condition-list',
          profileRule: "RECORDS.paymentConditions.read",
          href: ['company', company.id, 'records', 'payment-conditions']
        },
        {
          component: 'custom-state-list',
          profileRule: "RECORDS.additionalOSStates.read",
          href: ['company', company.id, 'records', 'custom-states']
        },
        {
          component: 'custom-state-budget-list',
          profileRule: "RECORDS.additionalBudgetStates.read",
          href: ['company', company.id, 'records', 'custom-states-budget']
        },
        {
          component: 'cashier-event-list',
          profileRule: "RECORDS.events.read",
          href: ['company', company.id, 'records', 'cashier-events']
        },
        {
          component: 'group-list',
          profileRule: "RECORDS.groups.read",
          href: ['company', company.id, 'records', 'groups']
        },
        {
          component: 'groups-by-expense-type-list',
          profileRule: "RECORDS.expenseGroups.read",
          href: ['company', company.id, 'records', 'groups-by-expense-type']
        },
        {
          component: 'brand-list',
          profileRule: "RECORDS.brands.read",
          href: ['company', company.id, 'records', 'brands']
        },
        {
          component: 'origin-list',
          profileRule: "RECORDS.origins.read",
          href: ['company', company.id, 'records', 'origin-list']
        },
        {
          component: 'tags',
          profileRule: "RECORDS.tags.read",
          href: ['company', company.id, 'records', 'tags']
        },
        {
          component: 'survey-list',
          profileRule: "RECORDS.surveys.read",
          href: ['company', company.id, 'records', 'survey-list'], shadows: ['survey-edit']
        },
        {
          component: 'subgroup-list',
          profileRule: "RECORDS.subgroups.read",
          href: ['company', company.id, 'records', 'subgroups']
        },
        {
          component: 'subgroup-by-expense-type-list',
          profileRule: "RECORDS.expenseSubgroups.read",
          href: ['company', company.id, 'records', 'subgroup-by-expense-type']
        },
        {
          component: 'calendar-class',
          profileRule: "RECORDS.calendarTypes.read",
          href: ['company', company.id, 'calendar', 'classlist']
        },
        {
          component: 'expense-list',
          profileRule: "RECORDS.expenseTypes.read",
          href: ['company', company.id, 'records', 'expense-types']
        },
        {
          component: 'ro-types',
          profileRule: "RECORDS.OStypes.read",
          href: ['company', company.id, 'records', 'ro-types']
        },
        {
          component: 'labor-types-list',
          profileRule: "RECORDS.laborTypes.read",
          href: ['company', company.id, 'records', 'labor-types']
        },
        {
          component: 'title-type-list',
          profileRule: "RECORDS.titleTypes.read",
          href: ['company', company.id, 'records', 'title-types']
        },
        {
          component: 'control-messages',
          profileRule: "RECORDS.controlMessages.read",
          href: ['company', company.id, 'records', 'control-messages']
        }
      ]
    },

    // settings
    {
      component: 'settings',
      blockedRoles: [roles.mechanic.id],
      icon: 'build',
      href: ['company', company.id, 'settings']
    },

  ];


  if (Array.isArray(user.quickAccess) && user.quickAccess.length) {
    const favoriteIndex = menus.findIndex(m => m.component === 'favorites');
    const configureChild = menus[favoriteIndex].childs.pop();
    const quickChilds: SideMenu['childs'] = [];
    for (const quick of user.quickAccess) {
      let child: SideMenu['childs'][0];
      for (const menu of menus) {
        if (menu.component === quick) {
          child = {
            component: quick,
            href: menu.href
          }
          continue
        } else if (menu.childs) {
          for (const menuChild of menu.childs) {
            if (menuChild.component === quick) {
              child = menuChild;
              continue;
            }
          }
        }
      }

      if (child) {
        quickChilds.push(child);
      }
    }

    quickChilds.push(configureChild);

    menus[favoriteIndex].childs = quickChilds;
  }

  return menus
}
