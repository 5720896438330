<footer [class.mobile]="(layout.isHandset$ | async)"
        *ngIf="!isMakena && !isPO360">
  <a class="image-link"
     *ngIf="APP_NAME === 'MekanicWEB'"
     href="https://www.tecinco.com.br"
     target="_blank"
     rel="noopener">
    <img class="developer-logo"
         src="/assets/images/tecinco.jpg"
         alt="logo tecinco" />
  </a>
  <div class="text">
    <div>{{ APP_NAME }}</div>
    <!-- <div class="txt-center">v{{ APP_VERSION }}</div> -->
  </div>
  <div class="social-group"
       *ngIf="APP_NAME === 'MekanicWEB'">
    <a alt="Facebook"
       href="https://www.facebook.com/tecinco/"
       aria-label="Nos acopanhe no Facebook!"
       target="_blank"
       rel="noopener">
      <img class=svgIcon
           src="/assets/images/icons/facebook-box.svg">
    </a>
    <a alt="Twitter"
       href="https://twitter.com/tecinco"
       aria-label="Nos siga no Twitter"
       target="_blank"
       rel="noopener">
      <img class="svgIcon"
           src="/assets/images/icons/twitter-box.svg">
    </a>
    <a alt="Linkedin"
       href="https://www.linkedin.com/company/tecinco-inform-tica-ltda-/"
       aria-label="Ir para o Linkedin"
       target="_blank"
       rel="noopener">
      <img class="svgIcon"
           src="/assets/images/icons/linkedin-box.svg">
    </a>
  </div>
</footer>


<div class="d-flex justify-content-end px-2 buttons"
     [class.mobile]="(layout.isHandset$ | async)">

  <button aria-label="back"
          title="Voltar"
          color="warn"
          *ngIf="layout.goBack"
          (click)="layout.goBack()"
          mat-fab>
    <mat-icon>arrow_back</mat-icon>
  </button>

  <button aria-label="back"
          color="warn"
          *ngIf="(layout.isHandset$ | async) && layout.rollUp"
          (click)="layout.rollUp()"
          mat-fab>
    <mat-icon>expand_less</mat-icon>
  </button>


  <button *ngIf="showSupport && !(layout.isHandset$ | async)"
          aria-label="support"
          id="support-button"
          title="Fale conosco"
          (click)="layout.support()"
          mat-fab>
    <mat-icon svgIcon="whatsapp"></mat-icon>
  </button>

  <button aria-label="join"
          title="Juntar-se"
          *ngIf="layout.join"
          id="join-button"
          mat-fab
          color="warn"
          (click)="layout.join()">
    <img alt="join group"
         src="/assets/images/SeekClipart.com_wasp-clipart-black-and_1733319.png">
  </button>

  <button aria-label="add"
          title="Novo"
          *ngIf="layout.add"
          mat-fab
          color="warn"
          (click)="layout.add()">
    <mat-icon>add</mat-icon>
  </button>

  <!-- create invoice -->
  <mkg-speed-dial *ngIf="layout.newInvoice"
                  [config]="layout.invoiceSpeedDialConfig"></mkg-speed-dial>

  @for (fab of layout.fabs; track fab.key) {
    <mkg-speed-dial [config]="fab.config" [initialState]="fab.initialState"></mkg-speed-dial>
  }

</div>
