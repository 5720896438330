import { Component, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { messageTypes } from 'src/app/shared/lists/message-types';
import { Message } from 'src/app/interface/message';
import { CompanyService } from 'src/app/service/company.service';
import { DataService } from 'src/app/service/data.service';
import { LayoutService } from 'src/app/service/layout.service';
import { MessageUtilities } from 'src/app/class/message-utilities';
import { filter, first } from 'rxjs';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss'
})
export class SidenavComponent{
  @Input() rightDrawer: MatSidenav;
  @Input() messages: Message[];
  @Input() unread: number;

  messageTypes = messageTypes;

  constructor(
    public companyService: CompanyService,
    public dataService: DataService,
    public layout: LayoutService
  ) {

  }
  ngOnInit() {}

  async readAllMessages() {
    this.dataService.company$.pipe(
      filter(c => !!c),
      first()
    ).subscribe(company =>{
      for (const message of this.messages) {
        if (!message.read) {
          message.read = true;
          this.unread--;
          MessageUtilities.read(message.id, company.id);
        }
      }
    })
  }

  openMessage(message: Message) {
    this.dataService.company$.pipe(
      filter(c => !!c),
      first()
    ).subscribe(comp => {
      if (!message.read) {
        message.read = true;
        this.unread--;
        MessageUtilities.read(message.id, comp.id);
      }
      this.rightDrawer.toggle();
      window.location.pathname = `/company/${comp.id}/os/detail/${message.text}`;
   })
  }
}
